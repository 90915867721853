import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddFeatured, deleteProperty } from "../../../redux/api";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SortableHandle } from "react-sortable-hoc";
const AFtableRow = ({
  index,
  title,
  uploadBy,
  order,
  id,
  handleDelete,
  description,
  date,
  category,
  size,
  playlist,
}) => {
  let arr = [];
  for (let i = 1; i <= size; i++) {
    arr.push(i);
  }

  const [orderval, setorderval] = useState(null);
  const RowHandler = SortableHandle((e) => (
    <a>
      <DragIndicatorIcon />
    </a>
  ));
  const handleClick = async (e) => {
    try {
      const temp = {
        videoIds: [`${id}`],
        order: e.target.value,
      };
      const yes = window.confirm("do you want to change order ?");
      if (yes) {
        await AddFeatured(temp);
        setorderval(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <tr>
        {/* <td>
          
          {id.slice(3, 7)}
        </td> */}
        {/* <td>
          <select name="" id="" onChange={(e) => handleClick(e)}>
            <option>{order}</option>
            {arr.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </td> */}
        <td ><RowHandler /> {title}</td>
        {/* <td>{}</td> */}
        {/* <td>{1}</td> */}
        <td>{category}</td>
        <td>{playlist}</td>
        <td>{uploadBy}</td>
        <button
          className="btn btn-outline-danger"
          onClick={() => handleDelete(id)}
        >
          <DeleteIcon />
        </button>

        {/* <td style={{ textAlign: 'center' }}>
          {property.unitDetails.length ? (
            <Link to={`#`}>
              <button className="btn btn-outline-secondary btn-sm">View</button>
            </Link>
          ) : (
            <Link to={`#`}>
              <button type="button" class="btn btn-outline-success btn-sm">
                Add
              </button>
            </Link>
          )}
        </td>
        <td style={{ textAlign: 'center' }}>
          {property.developer ? (
            <Link to={`#`}>
              <button className="btn btn-outline-secondary btn-sm">Edit</button>
            </Link>
          ) : (
            <Link to={`#`}>
              <button type="button" class="btn btn-outline-success btn-sm">
                Add
              </button>
            </Link>
          )}
        </td>
        <td className="text-right">
          <div
            className="actions"
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <Link to={'#'}>
              {' '}
              <button className="edit-btn">
                <ModeEditIcon />{' '}
              </button>
            </Link>
            <Link  to={'#'}>
              <button className="delete-btn">
                <DeleteIcon />{' '}
              </button>
            </Link>
          </div>
        </td> */}
      </tr>
      {/* {deleteModalOpen && (
        <DeleteModal
          show={deleteModalOpen}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel}
          categorytag="Property"
        />
      )} */}
    </>
  );
};

export default AFtableRow;
