import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteBlog } from '../../../redux/api';
import DeleteModal from '../../utils/DeleteModal';
const BtableRow = ({ index, title,time ,author,category,handleDelete,thumbnail,id}) => {
  // const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  // const [ConfirmDelete, setConfirmDelete] = useState(false);

  // const handleDeleteConfirm = () => {
  //   setdeleteModalOpen(false);
  //   setConfirmDelete(true);
  // };
  // const handleDeleteCancel = () => {
  //   setdeleteModalOpen(false);
  // };
  // const handleDeleteBlog = (e, id) => {
  //   e.preventDefault();
  //   setdeleteModalOpen(true);
  // };
  // const handleConfirmDeleteBlog = async (id) => {
  //   try {
  //     const updatedblogs = allblogs.filter((b) => b._id !== id);
  //     setallblogs(updatedblogs);
  //     await deleteBlog(id);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   if (ConfirmDelete) {
  //     handleConfirmDeleteBlog(blog._id);
  //   }
  // }, [ConfirmDelete]);

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{author}</td>
        <td>{time}</td>
        <td>{title}</td>
         <td style={{ textAlign: 'center' }}>
          <img
            src={thumbnail}
            height="100px"
            width="100px"
            alt="blog image"
          />
        </td>
        
        <td className="text-right">
          <div
            className="actions"
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <Link to={`/blog/edit/${id}`}>
              {' '}
              <button className="edit-btn">
                <ModeEditIcon />{' '}
              </button>
            </Link>
              <button  onClick={()=>handleDelete(id)}  className="delete-btn">
                <DeleteIcon />{' '}
              </button>
          </div>
        </td>
      </tr>
      {/* {deleteModalOpen && (
        <DeleteModal
          show={deleteModalOpen}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel}
          categorytag="Blog"
        />
      )} */}
    </>
  );
};

export default BtableRow;
