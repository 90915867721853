/** @format */

import React, { useEffect, useRef, useState } from 'react';
import '../../styles/newstyles/addBlogForm.css';
import { useHistory, useParams } from 'react-router-dom';
import { AddBlog, addBlog, getBlogById, updateBlog } from '../../redux/api';
import { storage } from '../../firebase';
import axios from 'axios';
import { Done, ForkRight, HourglassBottom } from '@mui/icons-material';
import { ShowActive } from '../../utils';
import { HindiBlog } from './BlogsInMultipleLng/HindiBlog';
import { EnglishBlog } from './BlogsInMultipleLng/EnglishBlog';
import { MarathiBlog } from './BlogsInMultipleLng/MarathiBlog';
import { TeluguBlog } from './BlogsInMultipleLng/TeluguBlog';
import { TamilBlog } from './BlogsInMultipleLng/TamilBlog';
import { useDispatch, useSelector } from 'react-redux';
import LoadingPage from '../utils/LoadingPage';
import { AddIntialDataBlog } from '../../blogReducer/blogAction';
import { toast } from 'react-hot-toast';
import { KannadBlog } from './BlogsInMultipleLng/KannadBlog';

const AddBlogForm = () => {
	const isFirstRender = useRef(true);
	const history = useHistory();
	const params = useParams();
	const { blogData } = useSelector((state) => state.blog);
	//upload Video
	const [spinn, setspinn] = useState(false);
	//upload Thumbnail
	const [loading, setloading] = useState(false);
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(1);

	const list = [
		{
			id: 1,
			title: 'Hindi',
		},
		{
			id: 2,
			title: 'English',
		},
		{
			id: 3,
			title: 'Marathi',
		},
		{
			id: 4,
			title: 'Telugu',
		},
		{
			id: 5,
			title: 'Tamil',
		},
		{
			id: 6,
			title: 'Kannada',
		},
	];

	const getRendered = (state) => {
		const Screen = {
			1: <HindiBlog />,
			2: <EnglishBlog />,
			3: <MarathiBlog />,
			4: <TeluguBlog />,
			5: <TamilBlog />,
			6: <KannadBlog />,
		};
		if (!Screen[state]) return null;
		return Screen[state];
	};

	const handlesubmit = async (e) => {
		e.preventDefault();

		setspinn(true);
		setTimeout(async () => {
			if (params.id) {
				const data = {
					...blogData,
					date: new Date(),
					_id: params.id,
				};
				try {
					await updateBlog(data);
					history.push('/blog');
					setspinn(false);
				} catch (err) {
					alert(err.message);
					toast.error(err.message);
				}
			} else {
				try {
					const data = {
						...blogData,
						date: new Date(),
					};
					await AddBlog(data);
					history.push('/blog');
					setspinn(false);
				} catch (error) {
					console.log(error);
					setspinn(false);
					toast.error(error.message);
				}
			}
		}, 2000);
	};

	useEffect(() => {
		if (params.id) {
			setloading(true);
			const fetchSingleBlog = async () => {
				const res = await getBlogById(params.id);
				dispatch(AddIntialDataBlog(res.data.data));
				setloading(false);
			};
			fetchSingleBlog();
		}
	}, [params.id]);

	return (
		<form>
			<div className='addproperty-container'>
				{loading ? (
					<LoadingPage />
				) : (
					<div>
						<div className='tabbutton mt-5 pt-2' id='tabbutton'>
							<ul className='d-flex justify-content-evenly'>
								{list.map((item) => (
									<ShowActive
										title={item.title}
										className='tabbutton'
										active={selected === item.id}
										setSelected={setSelected}
										id={item.id}
									/>
								))}
							</ul>
						</div>

						<div className='container'>{getRendered(selected)}</div>
						<div className='d-flex justify-content-center mb-5'>
							{selected > 1 && (
								<button
									className='btn btn-primary mt-3 mx-2'
									type='button'
									onClick={() =>
										setSelected(selected !== 1 ? selected - 1 : selected)
									}
								>
									&larr; Prev
								</button>
							)}
							{selected < 6 && (
								<button
									className='btn btn-primary mt-3 mx-2'
									style={{ height: '51px' }}
									type='button'
									onClick={() =>
										setSelected(selected !== 6 ? selected + 1 : selected)
									}
								>
									Next &rarr;
								</button>
							)}
							<button
								className='addproperty-submitDetailBtn mt-3'
								style={{ width: '40%' }}
								type='button'
								onClick={handlesubmit}
							>
								{params.id ? 'Update' : 'Submit All'}
								{spinn ? (
									<div
										style={{ width: '1rem' }}
										class='spinner-border spinner-border-sm text-white mx-2'
										role='status'
									>
										<span class='visually-hidden'>Loading...</span>
									</div>
								) : (
									''
								)}
							</button>
						</div>
					</div>
				)}
			</div>
		</form>
	);
};

export default AddBlogForm;
