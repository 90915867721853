/** @format */

import React from 'react';
import UtableRow from './UtableRow';
import '../../../styles/newstyles/table.css';

const Utable = ({ UserData }) => {
	return (
		<div className='table-wrapper' id='#scrollBar'>
			<table className='fl-table'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Phone</th>
						<th>Email</th>
						<th>Age</th>
						<th>Designation</th>
						<th>Organisation</th>
						<th>Username</th>
						<th>Liked</th>
						<th>Watched</th>
					</tr>
				</thead>
				<tbody>
					{UserData &&
						UserData.map((item, index) => {
							return (
								<UtableRow
									key={index}
									username={item.username}
									index={index}
									designation={item.designation}
									email={item.email}
									name={item.name}
									phone={item.phone}
									age={item.age}
									id={item._id}
									organisation={item.organisation}
								/>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};

export default Utable;
