/** @format */

import React, { useEffect, useState } from 'react';
import '../../styles/newstyles/dashboard.css';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import DynamicPagination from '../Pagination/Pagination';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
	categoryCount,
	GetAllTop,
	GetAllTop10,
	getAllUserPlaylist,
	GetAllVideos,
	GetCategory,
	getCompletedPlaylist,
	GetPlaylistStats,
	getSearchedData,
	GetTotalViews,
	getUserListOfLikedVideoes,
	getUserWatchedVideos,
	NoOfRegisteredUser,
	userLikedVideoes,
} from '../../redux/api';
import { Form, Modal, Pagination } from 'react-bootstrap';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CategoryIcon from '@mui/icons-material/Category';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { GetPlaylistViewLikes, getPlaylistViewLikes } from './helper';
import { useHistory } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import { SearchTable } from './SearchTable';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { toHoursAndMinutes } from '../utils/timeFormat';
import exportFromJSON from 'export-from-json';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: true,
			text: 'Chart.js Bar Chart',
		},
	},
};

const labels = [
	'October',
	'November',
	'December',
	'january',
	'February',
	'March',
];

const data = {
	labels,
	datasets: [
		{
			label: 'Agriculture',
			data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
		},
		{
			label: 'Farmer',
			data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
		},
	],
};

export function Dashboard() {
	const [user, setuser] = useState();
	const [statsData, setStatsData] = useState([]);
	const [lang, setLang] = useState('englishInfo');
	const [video, setVideo] = useState([]);
	const [modalOpened, setModalOpened] = useState(false);
	const [modalOpened1, setModalOpened1] = useState(false);
	const [modalOpened2, setModalOpened2] = useState(false);
	const [modalOpened7, setModalOpened7] = useState(false);
	const [modalOpened3, setModalOpened3] = useState(false);
	const [playlistCompleted, setPlaylistCompleted] = useState([]);
	const [modalOpened4, setModalOpened4] = useState({
		value: false,
		userName: '',
		userId: '',
	});
	const [modalOpened6, setModalOpened6] = useState({
		value: false,
		videoTitle: '',
		videoId: '',
		target: '',
	});
	const [modalOpened5, setModalOpened5] = useState(false);
	const [userWatchedVideos, setUserWatchedVideos] = useState([]);
	const [page, setPage] = useState(0);
	const [videoCount, setVideoCount] = useState(0);
	const [userListOfLikedVideoes, setUserListOfLikedVideoes] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [top50, setTop50] = useState([]);
	const [top10, setTop10] = useState([]);
	const [category, setCategory] = useState('any');
	const [playlist, setPlaylist] = useState([]);
	const [playlistCount, setPlaylistCount] = useState(0);
	const [totalViews, setTotalViews] = useState(0);
	const history = useHistory();

	const getCategory = async () => {
		try {
			const data = await Promise.all([categoryCount('category', category)]);
			setStatsData(data[0]?.data.result);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchTop50 = async () => {
		try {
			const data = await GetAllTop();
			setTop50(data?.data?.result);
		} catch (e) {
			console.error(e.messsage);
		}
	};

	const fetchTop10 = async () => {
		try {
			const data = await GetAllTop10();
			setTop10(data?.data?.result);
		} catch (e) {
			console.error(e.messsage);
		}
	};

	const getcategory = async () => {
		try {
			const data = await GetCategory();
			setCategory(data?.data?.category);
		} catch (error) {
			console.log(error);
		}
	};
	const getVideo = async () => {
		try {
			const data = await Promise.all([GetAllVideos(page)]);
			setVideo(data[0]?.data);
			setVideoCount(data[0]?.data?.pageLimit);
			console.log(data[0]?.data);
		} catch (err) {
			console.log(err);
		}
	};

	const getdata = async () => {
		try {
			const data = await Promise.all([NoOfRegisteredUser()]);
			setuser(data[0]?.data?.numberOfRegisteredUsers);
			console.log(data[1]?.data);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchSearchedData = async () => {
		try {
			const data = await Promise.all([getSearchedData()]);
			setSearchData(data[0]?.data.result);
		} catch (err) {
			console.log(err);
		}
	};

	const getPlaylistCompleted = async () => {
		try {
			const data = await getCompletedPlaylist();
			setPlaylistCompleted(Object.values(data?.data?.result));
		} catch (err) {
			console.log(err);
		}
	};

	const getPlaylist = async () => {
		try {
			const data = await GetPlaylistStats();
			setPlaylist(data?.data?.result);
			setPlaylistCount(data?.data?.pageLimit);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchTotalViews = async () => {
		try {
			const data = await GetTotalViews();
			setTotalViews(data?.data?.result);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getdata();
		getCategory();
		getcategory();
		fetchSearchedData();
		getPlaylist();
		getPlaylistCompleted();
		fetchTop50();
		fetchTop10();
		fetchTotalViews();
	}, []);

	useEffect(() => {
		getVideo();
	}, [page]);

	const fetchUserWatchedVideos = async (userId) => {
		try {
			const data = await getUserWatchedVideos(userId);
			setUserWatchedVideos(data.data.result);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (modalOpened4.value) {
			fetchUserWatchedVideos(modalOpened4.userId);
		}
	}, [modalOpened4.value]);

	const fetchUserLikes = async (videoId) => {
		try {
			const data = await userLikedVideoes(videoId);
			setUserListOfLikedVideoes(data.data.result);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		if (modalOpened6.value) {
			fetchUserLikes(modalOpened6.videoId);
		}
	}, [modalOpened6.value]);

	const mostPlayedVideo =
		video?.data?.length &&
		video?.data
			.sort((a, b) => b?.videoStatId?.views - a?.videoStatId?.views)
			?.slice(0, 10);

	const downloadAllSearchTerm = () => {
		const fileName = 'search-term';
		const exportType = exportFromJSON.types.csv;
		const data = searchData;
		exportFromJSON({ data, fileName, exportType });
	};

	return (
		<>
			<section className='d-flex flex-column align-items-center'>
				<div className='d-flex p-4 justify-content-between align-items-center'>
					<div
						className='box-1 cursor-pointer'
						onClick={() => history.push('/users')}
					>
						<h5>
							Total User
							<PeopleIcon className='mx-2' />
						</h5>
						<p>{(user && user) || 0}</p>
					</div>
					<div className='box-2'>
						<h5>
							Total Views
							<PlayCircleIcon className='mx-2' />
						</h5>
						<p>{totalViews}</p>
					</div>
					<div
						className='box-1 cursor-pointer box-hover'
						onClick={() => setModalOpened(true)}
					>
						<h5>
							Videos
							<OndemandVideoIcon className='mx-2' />
						</h5>
						<p>{videoCount || 0}</p>
					</div>
					<div
						className='box-2 cursor-pointer box-hover'
						onClick={() => setModalOpened1(true)}
					>
						<h5>
							Category
							<CategoryIcon className='mx-2' />
						</h5>
						<p>{category?.length || 0}</p>
					</div>
				</div>
				<div className='d-flex p-4 justify-content-between align-items-center'>
					<div
						className='box-1 cursor-pointer box-hover'
						onClick={() => setModalOpened2(true)}
					>
						<h5>
							Playlist
							<SubscriptionsIcon className='mx-2' />
						</h5>
						<p>{playlistCount}</p>
					</div>
					<div
						className='box-2 cursor-pointer'
						onClick={() => setModalOpened5(true)}
					>
						<h5>Top 50 Users Watch Videos</h5>
						<p>{top50?.length && top50?.length}</p>
					</div>
					<div
						className='box-1 cursor-pointer'
						onClick={() => setModalOpened3(true)}
					>
						<h5>Top 10 Completed Video</h5>
						<p>{top10?.length && top10.length}</p>
					</div>
					<div
						className='box-1 cursor-pointer'
						onClick={() => setModalOpened7(true)}
					>
						<h5>Top Completed Playlist</h5>
						<p>
							{(playlistCompleted?.length && playlistCompleted.length) || 0}
						</p>
					</div>
				</div>
				<div className='d-flex align-items-center mt-5'>
					<h5 className='text-center'>Top 10 Search List</h5>
					<button
						className='btn-primary-admin mx-4'
						onClick={() => downloadAllSearchTerm()}
					>
						<CloudDownloadIcon /> Export All Search Terms
					</button>
				</div>
				<div className='col-10'>
					<SearchTable QueryData={searchData.slice(-10)} />
				</div>
			</section>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened}
				onHide={() => setModalOpened(false)}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>Video List</h5>
				</Modal.Header>
				<div className='modal-body'>
					<div className='content'>
						<div
							className='table-wrapper'
							style={{ height: '48vh' }}
							id='#scrollBar'
						>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>Title</th>
										<th>Category</th>
										<th>Duration</th>
										<th>Total Views</th>
										<th>Total Likes</th>
									</tr>
								</thead>
								<tbody>
									{mostPlayedVideo?.length > 0 &&
										mostPlayedVideo?.map((item, index) => (
											<tr key={item._id}>
												<td>{item.englishInfo.title}</td>

												<td>
													{item.category
														? item.category.name
														: 'No Category Assigned'}
												</td>
												<td>{toHoursAndMinutes(item.duration) || 1}</td>
												<td>{item?.videoStatId?.views}</td>
												<td
													onClick={() => {
														setModalOpened(false);
														setModalOpened6({
															...modalOpened6,
															value: true,
															videoId: item._id,
															videoTitle:
																item?.englishInfo?.title || 'No Title',
														});
													}}
												>
													<a
														className={`${
															item?.videoStatId?.likes !== 0 &&
															`hover-underline cursor-pointer`
														}`}
													>
														{item?.videoStatId?.likes}
													</a>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<div className='d-flex justify-content-end'>
							<DynamicPagination
								totalPages={Math.ceil(videoCount / 10)}
								page={page}
								setPage={setPage}
							/>
							{/* <Pagination>
								<Pagination.First onClick={() => setPage(0)} />
								<Pagination.Prev
									disabled={page === 0}
									onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
								/>

								{[...Array(pageLimit)].map((pa, index) => (
									<Pagination.Item
										key={index}
										onClick={() => setPage(index)}
										active={index === page}
									>
										{index + 1}
									</Pagination.Item>
								))}
								<Pagination.Next onClick={() => setPage((prev) => prev + 1)} />
								<Pagination.Last onClick={() => setPage(pageLimit)} />
							</Pagination> */}
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened5}
				onHide={() => setModalOpened5(false)}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>Watching List</h5>
				</Modal.Header>
				<div
					className='modal-body'
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>ID</th>
										<th>User Name</th>
										<th>User Email</th>
										<th>Total Video Watched</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{top50?.map((item, index) => (
										<tr key={item._id}>
											<td>{index + 1}</td>
											<td>{item.user.name}</td>
											<td>{item.user.email}</td>
											<td>{item.totalVideos}</td>
											<td>
												<a
													className='cursor-pointer hover-underline'
													onClick={() => {
														setModalOpened4({
															...modalOpened4,
															userName: item.user.name,
															userId: item.user._id,
															value: true,
														});
														setModalOpened5(false);
													}}
												>
													View Videos
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened4.value}
				onHide={() => {
					setModalOpened4({ ...modalOpened4, value: false });
					setModalOpened5(true);
				}}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>
						{modalOpened4.userName} Watched Video List
					</h5>
				</Modal.Header>
				<div
					className='modal-body'
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>ID</th>
										<th>Video Title</th>
										<th>Duration</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									{userWatchedVideos?.map((item, index) => (
										<tr key={item._id}>
											<td>{index + 1}</td>
											<td>{item.englishInfo.title}</td>
											{toHoursAndMinutes(item.duration) || 1}
											<td>{item.englishInfo.description}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened6.value}
				onHide={() => {
					setModalOpened6({ ...modalOpened6, value: false });
					modalOpened6.target === 'top10'
						? setModalOpened3(true)
						: setModalOpened(true);
				}}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>
						{modalOpened6.videoTitle} Liked By User List
					</h5>
				</Modal.Header>
				<div
					className='modal-body'
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>ID</th>
										<th>User Id</th>
										<th>User Name</th>
									</tr>
								</thead>
								<tbody>
									{userListOfLikedVideoes?.map((item, index) => (
										<tr key={item._id}>
											<td>{index + 1}</td>
											<td>{item.userId}</td>
											<td>{item.name}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened2}
				onHide={() => setModalOpened2(false)}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>Playlist</h5>
				</Modal.Header>
				<div
					className='modal-body'
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>Playlist Name</th>
										<th>Total Views</th>
										<th>Duration(in Hours)</th>
										<th>Total Video</th>
										<th>Total Likes</th>
									</tr>
								</thead>
								<tbody>
									{playlist?.map((item, index) => (
										<tr key={index}>
											<td>{item.playlistName || 'No Playlist Assigned'}</td>
											<td>{item.totalViews}</td>
											<td>{toHoursAndMinutes(item.totalDuration) || 1}</td>
											<td>{item.totalVideos || 3}</td>
											<td>{item.totalLikes}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened3}
				onHide={() => setModalOpened3(false)}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>Top 10 Completed Videoes List</h5>
				</Modal.Header>
				<div
					className='modal-body'
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>ID</th>
										<th>Video Title</th>
										<th>Duration(in Hours)</th>
										<th>Total Users</th>
									</tr>
								</thead>
								<tbody>
									{top10?.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{item?.video.englishInfo.title || ''}</td>
											<td>{toHoursAndMinutes(item?.video?.duration) || 1}</td>
											<td
												onClick={() => {
													setModalOpened6({
														...modalOpened6,
														value: true,
														videoTitle: item?.video?.englishInfo.title,
														videoId: item.video?._id,
														target: 'top10',
													});
													setModalOpened3(false);
												}}
											>
												<a
													className={`${
														item.totalUsers !== 0 &&
														`hover-underline cursor-pointer`
													}`}
												>
													{item.totalUsers}
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened1}
				onHide={() => setModalOpened1(false)}
			>
				<div style={{ zIndex: 80 }}>
					<Modal.Header closeButton>
						<div className='col-6'>
							<h5 className='modal-title'>Category List</h5>
						</div>
						<div className='col-5 mx-2'>
							{/* <Form.Select
								className='w-100'
								onChange={(e) => setLang(e.target.value)}
							>
								<option selected value='englishInfo'>
									English
								</option>
								<option value='hindiInfo'>Hindi</option>
								<option value='marathiInfo'>Marathi</option>
								<option value='teluguInfo'>Telugu</option>
								<option value='tamilInfo'>Tamil</option>
								<option value='kannadInfo'>Kannad</option>
							</Form.Select> */}
						</div>
					</Modal.Header>
					<div
						className='modal-body'
						style={{ height: '450px', overflowY: 'auto' }}
					>
						<div className='content'>
							<div className='table-wrapper' id='#scrollBar'>
								<table className='fl-table'>
									<thead>
										<tr>
											<th>Category Name</th>
											<th>Total Duration</th>
											<th>Total Views</th>
											<th>Total Videos</th>
											<th>Total Likes</th>
										</tr>
									</thead>
									<tbody>
										{statsData?.map((item, index) => (
											<tr key={item._id}>
												<td>{item.category[lang]}</td>
												<td>
													{lang === 'englishInfo'
														? toHoursAndMinutes(item.totalDuration)
														: 'No Data'}
												</td>
												<td>
													{lang === 'englishInfo' ? item.totalViews : 'No Data'}
												</td>
												<td>
													{lang === 'englishInfo'
														? item.totalVideos
														: 'No Data'}
												</td>
												<td>
													{lang === 'englishInfo' ? item.totalLikes : 'No Data'}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modalOpened7}
				onHide={() => setModalOpened7(false)}
			>
				<Modal.Header closeButton>
					<div className='col-6'>
						<h5 className='modal-title'>Completed Playlist</h5>
					</div>
				</Modal.Header>
				<div
					className='modal-body'
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>Id</th>
										<th>Playlist Name</th>
										<th>User Name</th>
										<th>User Email </th>
									</tr>
								</thead>
								<tbody>
									{playlistCompleted?.map((item, index) => (
										<tr key={item._id}>
											<td>{index + 1}</td>
											<td>
												{item?.playlist?.map((item) => (
													<p>{item?.name || 'Untitle Playlist'}</p>
												))}
											</td>
											<td>{item.user.name}</td>
											<td>{item.user.email}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default Dashboard;
