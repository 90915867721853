import React, { useState, useEffect } from "react";
import { AddToPlaylist } from "../../../../redux/api";
import "../../../../styles/newstyles/table.css";
import AAVtableRow from "./AAVtableRow";
import { useParams } from "react-router-dom";
const AAVtable = ({
  QueryData,
  handleRemoval,
  handleAddition,
  playlistVideos,
}) => {
  return (
    <>
      {QueryData ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Add To Playlist</th>
              </tr>
            </thead>
            <tbody>
              {QueryData.map((item, index) => {
                return (
                  <AAVtableRow
                    key={index}
                    index={index}
                    videoid={item._id}
                    playlistVideos={playlistVideos}
                    handleAddition={handleAddition}
                    handleRemoval={handleRemoval}
                    title={item?.englishInfo.title || "No Title"}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};

export default AAVtable;
