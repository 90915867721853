import React, { useState, useEffect, useCallback } from "react";
import AFtableRow from "./AFtableRow";
import "../../../styles/newstyles/table.css";
import { AddFeatured, updatePlaylistVideo } from "../../../redux/api";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "../../Playlist/AllPlaylist/arrayMove";

const AFtable = ({ VideoData }) => {
  const [video, setvideo] = useState(VideoData);
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setvideo((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  });
  const SortableItem = SortableElement((props) => <AFtableRow {...props} />);
  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });
  const handleDelete = async (id) => {
    try {
      const temp = {
        videoIds: [`${id}`],
        order: -1,
      };
      const yes = window.confirm("do you want to delete ?");
      if (yes) {
        const newarr = video.filter((item) => item._id != id);
        console.log(newarr);
        setvideo(newarr);
        await AddFeatured(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (video.length) {
      const updateOrderData = async () => {
        try {
          const newVideo = video.map((item, index) => {
            item.featureOrder = index + 1;
            return item;
          });
          const response = await updatePlaylistVideo({
            featureOrders: newVideo,
          });
        } catch (error) {
          console.log(error);
        }
      };
      updateOrderData();
    }
  }, [video]);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            {/* <th>ID</th> */}
            {/* <th>Order</th> */}
            <th>Title</th>
            {/* <th>Likes</th> */}
            {/* <th>Dislikes</th> */}
            <th>Category</th>
            <th>Playlist</th>
            <th>Uploaded by</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["30%", "50%"]}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {video &&
            video.map((video, index) => {
              return (
                <SortableItem
                  key={index}
                  index={index}
                  id={video._id}
                  title={video.englishInfo.title}
                  description={video.description}
                  uploadBy={video.uploadedBy}
                  date={video.date}
                  category={
                    video.category
                      ? video.category.name
                      : "No Category Assigned"
                  }
                  order={video.order}
                  handleDelete={handleDelete}
                  playlist={
                    video.playlist
                      ? video.playlist.name
                      : "No Playlist Assigned"
                  }
                  size={VideoData?.length}
                />
              );
            })}
        </SortableCont>
      </table>
    </div>
  );
};

export default AFtable;
