import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";

const NtableRow = ({ name, index, active, uuid, id, handleDelete, link }) => {
  const history = useHistory();
  return (
    <>
      <tr>
        <td>{id}</td>
        <td style={{ width: "40%" }}>{name}</td>
        <td>{link}</td>
        <td>{active === true ? "Active" : "Not Active"}</td>
        <td>
          <button
            onClick={() => history.push("/notificationpanel/add/" + uuid)}
            className="btn btn-outline-success mx-2"
          >
            <EditIcon />
          </button>
          <button
            onClick={handleDelete}
            className="btn btn-outline-danger mx-2"
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>
    </>
  );
};

export default NtableRow;
