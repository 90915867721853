import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoadingPage from "../../utils/LoadingPage";
import addIcon from "../../../images/addIcon.svg";
import searchIcon from "../../../images/searchIcon.svg";
import "../../../styles/newstyles/unitdetails.css";
import AAVtable from "./AddAllVideos/AAVtable";
import {
  AddToPlaylist,
  GetAllVideos,
  GetAllVideosOfPlaylist,
  GetCategory,
  GetQuery,
  PostCategory,
} from "../../../redux/api";
import { Pagination } from "react-bootstrap";

const ALLADDVideos = () => {
  const history = useHistory();
  const { id, name } = useParams();
  const param = useParams();
  const [QueryData, setQueryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setsearchInput] = useState("");
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [page, setPage] = useState(0);
  const pageLimit = Math.ceil(videoCount / 10);
  useEffect(() => {
    GetData();
  }, [page]);

  // api call

  const GetData = async () => {
    try {
      const data = await GetAllVideos(0, 10000);
      const data2 = await GetAllVideos(0, 10000, `&playlist=${id}`);
      setQueryData(data?.data?.data);
      setPlaylistVideos(data2?.data?.data);
      setVideoCount(data?.data?.pageLimit);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchItems = (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      let filteredData = QueryData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setfilterData(filteredData);
    } else {
      setfilterData(QueryData);
    }
  };

  /// video addition

  const [videos, setvideos] = useState([]);

  const temp = {
    videoIds: videos,
    playlist: `${id}`,
    action: 1,
  };
  const PostData = async () => {
    try {
      const data = await AddToPlaylist(temp);
      window.alert("Videos Added");
      history.push(`/playlist/videos/${name}/${id}`);
      console.log(data);
    } catch (err) {
      window.alert("Couldn't add videos");
      console.log(err);
    }
  };

  //handleaction
  const handleAddition = (id) => {
    // setvideos({...videos,ids : [...videos.ids,id]})
    setvideos([...videos, id]);
  };
  const handleRemoval = (id) => {
    const item = videos?.indexOf(id);
    setvideos(videos?.filter((item) => item != id));
    console.log(item);
  };

  return (
    <div className="unitdetails-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="unitdetails-firstSection">
            <div className="unitdetails-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Name , Description or More"
                className="unitdetails-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button className="property-addBtn" onClick={PostData}>
                <span>Submit</span>
              </button>
            </div>
          </div>
          <h5 className="my-4">{name}</h5>
          <div className="unitdetails-tableSection">
            {searchInput.length ? (
              <AAVtable
                QueryData={filterData}
                playlistVideos={playlistVideos}
                handleRemoval={handleRemoval}
                handleAddition={handleAddition}
              />
            ) : (
              <AAVtable
                QueryData={QueryData}
                playlistVideos={playlistVideos}
                handleRemoval={handleRemoval}
                handleAddition={handleAddition}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default ALLADDVideos;
