/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import '../../../styles/newstyles/table.css';
import CtableRow from './CtableRow';
import {
	categoryOrder,
	deleteCategory,
	PostCategory,
} from '../../../redux/api';
import toast, { Toaster } from 'react-hot-toast';
import arrayMove from '../../Playlist/AllPlaylist/arrayMove';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const Ctable = ({ QueryData }) => {
	const [category, setcategory] = useState([]);
	const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
		setcategory((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
	}, []);
	const SortableItem = SortableElement((props) => <CtableRow {...props} />);
	const SortableCont = SortableContainer(({ children }) => {
		return <tbody>{children}</tbody>;
	});
	const handleDelete = async (id) => {
		try {
			const yes = window.confirm('Do you want delete ?');
			if (yes) {
				let newarr = category.filter((item) => item._id != id);
				const data = await deleteCategory(id);
				console.log(data);
				toast.success('Category Deleted');
				setcategory(newarr);
			}
		} catch (error) {
			toast.success("Couldn't Delete the Category");
			console.log(error);
		}
	};
	useEffect(() => {
		if (category.length) {
			const updateOrderData = async () => {
				try {
					const newCategory = category.map((item, index) => {
						item.order = index + 1;
						return item;
					});
					const response = await categoryOrder({
						orders: newCategory,
					});
					toast.success('Order Updated Successfully!');
				} catch (error) {
					console.log(error);
				}
			};
			updateOrderData();
		}
	}, [category]);
	//update the state before loading
	useEffect(() => {
		setcategory(QueryData);
	}, [QueryData]);

	return (
		<>
			<Toaster />
			{QueryData ? (
				<div className='table-wrapper' id='#scrollBar'>
					<table className='fl-table'>
						<thead>
							<tr>
								{/* <th>ID</th> */}
								<th>Name</th>
								<th className='text-center'>Action</th>
							</tr>
						</thead>
						<SortableCont
							onSortEnd={onSortEnd}
							axis='y'
							lockAxis='y'
							lockToContainerEdges={true}
							lockOffset={['30%', '50%']}
							helperClass='helperContainerClass'
							useDragHandle={true}
						>
							{category &&
								category.map((item, index) => {
									return (
										<SortableItem
											key={index}
											index={index}
											name={item.name}
											id={item._id}
											handleDelete={handleDelete}
										/>
									);
								})}
						</SortableCont>
					</table>
				</div>
			) : (
				<h6 className='text-center'>No details available</h6>
			)}
		</>
	);
};

export default Ctable;
