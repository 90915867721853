import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingPage from "../../utils/LoadingPage";
import addIcon from "../../../images/addIcon.svg";
import searchIcon from "../../../images/searchIcon.svg";
import "../../../styles/newstyles/property.css";
import AFVtable from "./AFVtable";
import {
  AddFeatured,
  getAllProperty,
  GetAllVideos,
  GetFeatureVideo,
  GetFeatureVideoToAdd,
} from "../../../redux/api";
import { Pagination } from "react-bootstrap";

const AddFeaturedVideos = () => {
  const history = useHistory();
  const [allpropertyData, setallpropertyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [page, setPage] = useState(0);
  const pageLimit = Math.ceil(videoCount / 10);
  const fetchpropertyList = async () => {
    setLoading(true);
    try {
      const res = await GetFeatureVideoToAdd(page);
      console.log(res);
      setallpropertyData(res?.data?.data);
      setVideoCount(res?.data?.pageLimit);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchpropertyList();
  }, [page]);

  const searchItems = (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      let filteredData = allpropertyData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setfilterData(filteredData);
    } else {
      setfilterData(allpropertyData);
    }
  };

  // videos

  const [videos, setvideos] = useState([]);

  console.log(videos);

  const handleAddition = (id) => {
    // setvideos({...videos,ids : [...videos.ids,id]})
    setvideos([...videos, id]);
  };
  const handleRemoval = (id) => {
    const item = videos?.indexOf(id);
    setvideos(videos?.filter((item) => item != id));
    console.log(item);
  };

  const handleSubmit = async () => {
    const temp = {
      videoIds: [...videos],
      order: 1,
      isFeatured: true,
    };
    try {
      await AddFeatured(temp);
      history.push("/featuredvideos");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title , Author or Category"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button className="property-addBtn" onClick={handleSubmit}>
                <span>Submit</span>
              </button>
            </div>
          </div>
          <div className="property-tableSection">
            {searchInput.length ? (
              <AFVtable
                VideoData={filterData}
                handleAddition={handleAddition}
                handleRemoval={handleRemoval}
              />
            ) : (
              <AFVtable
                VideoData={allpropertyData}
                handleRemoval={handleRemoval}
                handleAddition={handleAddition}
              />
            )}
          </div>
          <div className="property-pagination d-flex justify-content-end mb-5">
            <div>
              <Pagination>
                <Pagination.First onClick={() => setPage(0)} />
                <Pagination.Prev
                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
                />

                {[...Array(pageLimit)].map((pa, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(index)}
                    active={index === page}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setPage((prev) => prev + 1)} />
                <Pagination.Last onClick={() => setPage(pageLimit)} />
              </Pagination>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default AddFeaturedVideos;
