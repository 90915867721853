/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import '../../../styles/newstyles/table.css';
import PtableRow from './PtableRow';
import toast, { Toaster } from 'react-hot-toast';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
	deletePlaylist,
	GetCategory,
	PostCategory,
	updateOrder,
} from '../../../redux/api';
import arrayMove from './arrayMove';

const Ptable = ({ QueryData }) => {
	const [orderNumber, setOrderNumber] = useState();
	const [category, setCategoryData] = useState([]);
	const [playlist, setplaylist] = useState([]);
	const [newPlaylist, setNewPlaylist] = useState([]);
	const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
		setplaylist((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
	});
	const SortableItem = SortableElement((props) => <PtableRow {...props} />);
	const SortableCont = SortableContainer(({ children }) => {
		return <tbody>{children}</tbody>;
	});
	const handleDelete = async (id) => {
		try {
			const yes = window.confirm('Do you want delete ?');
			if (yes) {
				let newarr = playlist.filter((item) => item._id != id);
				const data = await deletePlaylist(id);
				console.log(data);
				toast.success('Playlist Deleted');
				setplaylist(newarr);
			}
		} catch (error) {
			toast.success("Couldn't Delete the Playlist");
			console.log(error);
		}
	};
	const categoryGet = async () => {
		try {
			const data = await GetCategory();
			setCategoryData(data?.data?.category);
		} catch (error) {
			console.log(error);
		}
	};

	//update the state before loading
	useEffect(() => {
		categoryGet();
		setplaylist(QueryData);
	}, [QueryData]);

	useEffect(() => {
		if (playlist.length) {
			const updateOrderData = async () => {
				try {
					const newPlaylist = playlist.map((item, index) => {
						return {
							_id: item._id,
							order: index + 1,
						};
					});
					const response = await updateOrder({
						orders: newPlaylist,
					});
					setNewPlaylist([]);
					toast.success('Order Updated Successfully!');
				} catch (error) {
					console.log(error);
				}
			};
			updateOrderData();
		}
	}, [playlist]);

	return (
		<>
			<Toaster />
			{QueryData ? (
				<div className='table-wrapper' id='#scrollBar'>
					<table className='fl-table'>
						<thead>
							<tr>
								{/* <th>ID</th> */}
								<th>Name</th>
								<th>View Videos</th>
								<th>Add Videos</th>
								<th>No. Of Videos</th>
								<th>Category</th>
								<th className='text-center'>Action</th>
							</tr>
						</thead>
						<SortableCont
							onSortEnd={onSortEnd}
							axis='y'
							lockAxis='y'
							lockToContainerEdges={true}
							lockOffset={['30%', '50%']}
							helperClass='helperContainerClass'
							useDragHandle={true}
						>
							{playlist?.map((item, index) => {
								return (
									<SortableItem
										key={`item-${index}`}
										index={index}
										name={item.name}
										categoryName={item?.categoryId[0]?.name}
										category={category}
										totalVideos={item.totalVideos.length}
										id={item._id}
										className='table-row'
										handleDelete={handleDelete}
									/>
								);
							})}
						</SortableCont>
					</table>
				</div>
			) : (
				<h6 className='text-center'>No details available</h6>
			)}
		</>
	);
};

export default Ptable;
