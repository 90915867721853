import React, { useState, useEffect } from "react";
import BtableRow from "./BtableRow";
import "../../../styles/newstyles/table.css";
import { DeleteBlog } from "../../../redux/api";
import toast, { Toaster } from "react-hot-toast";

const Btable = ({ blogData }) => {
  const [blogs, setblogs] = useState([]);

  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        let newarr = blogs.filter((item) => item._id != id);
        await DeleteBlog(id);
        toast.success("Blog Deleted");
        setblogs(newarr);
      }
    } catch (error) {
      toast.success("Couldn't Delete the Blog");
      console.log(error);
    }
  };

  useEffect(() => {
    setblogs(blogData);
  }, [blogData]);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <Toaster />
      <table className="fl-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Author</th>
            <th>Read Time</th>
            <th>Title</th>
            {/* <th>Category</th> */}
            <th style={{ textAlign: "center" }}>Blog Picture</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {blogs?.map((blog, index) => {
            return (
              <BtableRow
                key={index}
                index={index}
                author={blog.englishInfo.author}
                id={blog._id}
                time={blog.readTime}
                category={blog.category}
                thumbnail={blog.thumbnail}
                title={blog.englishInfo.title}
                handleDelete={handleDelete}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Btable;
