import React, { useState, useEffect } from "react";
import "../../styles/newstyles/table.css";

import { SearchTableRow } from "./SearchTableRow";

export const SearchTable = ({ QueryData }) => {
  const [searchList, setSearchList] = useState([]);
  useEffect(() => {
    setSearchList(QueryData);
  }, [QueryData]);

  return (
    <>
      {QueryData ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <thead>
              <tr>
                <th>ID</th>
                <th className="text-left">Search Term</th>
              </tr>
            </thead>
            <tbody>
              {searchList.map((item, index) => {
                return (
                  <SearchTableRow
                    key={index}
                    index={index}
                    name={item.term}
                    id={item._id}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};
