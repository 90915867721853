/** @format */

import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';

const DynamicPagination = ({ totalPages, setPage, page }) => {
	const [currentPage, setCurrentPage] = useState(page + 1);

	const handlePaginationClick = (page) => {
		setCurrentPage(page);
		setPage(page - 1);
		// You can perform any additional actions here, like fetching data for the selected page.
	};

	const getPageNumbers = () => {
		const pageNumbers = [];
		const maxVisiblePages = 5; // Adjust as needed
		const halfVisiblePages = Math.floor(maxVisiblePages / 2);
		let startPage = Math.max(1, currentPage - halfVisiblePages);
		let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

		if (endPage - startPage < maxVisiblePages - 1) {
			startPage = Math.max(1, endPage - maxVisiblePages + 1);
		}

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		if (startPage > 1) {
			pageNumbers.unshift('...');
		}
		if (endPage < totalPages) {
			pageNumbers.push('...');
		}

		return pageNumbers;
	};

	return (
		<Pagination>
			<Pagination.First onClick={() => handlePaginationClick(1)} />
			<Pagination.Prev
				onClick={() =>
					handlePaginationClick(currentPage > 1 ? currentPage - 1 : 1)
				}
			/>
			{getPageNumbers().map((page, index) => (
				<Pagination.Item
					key={index}
					active={page === currentPage}
					onClick={() => handlePaginationClick(page)}
					disabled={page === '...'}
				>
					{page}
				</Pagination.Item>
			))}
			<Pagination.Next
				onClick={() =>
					handlePaginationClick(
						currentPage < totalPages ? currentPage + 1 : totalPages
					)
				}
			/>
			<Pagination.Last onClick={() => handlePaginationClick(totalPages)} />
		</Pagination>
	);
};

export default DynamicPagination;
