/** @format */

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addInitialTags, AddTags } from '../../../blogReducer/blogAction';

export const TagsInput = (props, lng) => {
	const [tags, setTags] = useState(props.tags);
	const dispatch = useDispatch();
	const [tag, setTag] = useState('');
	const removeTags = (indexToRemove) => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};
	const addTags = (event) => {
		if (event.target.value !== '') {
			setTags([...tags, event.target.value]);
			setTag('');
			event.target.value = '';
		}
	};

	useEffect(() => {
		dispatch(
			addInitialTags({
				hindiInfo: {
					tags: [],
				},
				englishInfo: {
					tags: [],
				},
				marathiInfo: {
					tags: [],
				},
				kannadInfo: {
					tags: [],
				},
				teluguInfo: {
					tags: [],
				},
				tamilInfo: {
					tags: [],
				},
			})
		);
		if (tags?.length) {
			dispatch(
				AddTags({
					language: props.lng,
					data: tags,
				})
			);
		}
	}, [tags?.length]);

	return (
		<div className='tags-input'>
			<ul id='tags'>
				{tags?.map((tag, index) => (
					<li key={index} className='tag'>
						<span className='tag-title'>{tag}</span>
						<span className='tag-close-icon' onClick={() => removeTags(index)}>
							x
						</span>
					</li>
				))}
			</ul>
			<input
				type='text'
				onKeyUp={(event) => (event.key === 'Enter' ? addTags(event) : null)}
				placeholder='Press enter to add tags'
			/>
		</div>
	);
};
