/** @format */

import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
	getUserListOfLikedVideoes,
	getUserWatchedVideos,
} from '../../../redux/api';
import { toHoursAndMinutes } from '../../utils/timeFormat';
import DynamicPagination from '../../Pagination/Pagination';
const UtableRow = ({
	index,
	name,
	id,
	organisation,
	designation,
	age,
	email,
	username,
	phone,
}) => {
	const [likedVideoOfUser, setLikedVideos] = useState([]);
	const [likepage, setLikePage] = useState(0);
	const [liketotal, setLikeTotal] = useState(1);
	const [modal, setModal] = useState({
		modal1: false,
		modal2: false,
		username: '',
	});
	const [watchedVideoByUser, setWathedVideoByUser] = useState([]);
	const [watchpage, setWatchPage] = useState(0);
	const [watchtotal, setWatchTotal] = useState(1);
	const fetchLike = async () => {
		try {
			const data = await getUserListOfLikedVideoes(id, likepage);
			setLikedVideos(data?.data?.result);
			setLikeTotal(data?.data?.pageLimit);
		} catch (error) {}
	};
	const fetchWatch = async () => {
		try {
			const data = await getUserWatchedVideos(id, watchpage);
			setWathedVideoByUser(data?.data?.result);
			setWatchTotal(data?.data?.pageLimit);
		} catch (error) {}
	};

	useEffect(() => {
		fetchLike();
	}, [id, likepage]);

	useEffect(() => {
		fetchWatch();
	}, [id, watchpage]);

	return (
		<>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modal.modal1}
				onHide={() => {
					setModal({ ...modal, modal1: false });
				}}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>{modal.username} Watched Videoes List</h5>
				</Modal.Header>
				<div className='modal-body'>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>ID</th>
										<th>Video Title</th>
										<th>Duration</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									{watchedVideoByUser?.map((item, index) => (
										<tr key={item._id}>
											<td>{index + 1}</td>
											<td>{item.englishInfo.title}</td>
											<td>{toHoursAndMinutes(item?.duration || 0)}</td>
											<td>{item.englishInfo.description}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<DynamicPagination
						totalPages={watchtotal}
						page={watchpage}
						setPage={setWatchPage}
					/>
				</div>
			</Modal>
			<Modal
				centered
				className='mx-5'
				size='lg'
				show={modal.modal2}
				onHide={() => {
					setModal({ ...modal, modal2: false });
				}}
			>
				<Modal.Header closeButton>
					<h5 className='modal-title'>{modal.username} Liked Videoes List</h5>
				</Modal.Header>
				<div className='modal-body'>
					<div className='content'>
						<div className='table-wrapper' id='#scrollBar'>
							<table className='fl-table'>
								<thead>
									<tr>
										<th>ID</th>
										<th>Video Title</th>
										<th>Duration</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									{likedVideoOfUser?.map((item, index) => (
										<tr key={item._id}>
											<td>{index + 1}</td>
											<td>{item.video.englishInfo?.title}</td>
											<td>{toHoursAndMinutes(item?.video?.duration || 0)}</td>
											<td>{item.video?.englishInfo.description}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<DynamicPagination
						totalPages={liketotal}
						page={likepage}
						setPage={setLikePage}
					/>
				</div>
			</Modal>
			<tr>
				<td>{index + 1}</td>
				<td>{name}</td>
				<td>{phone}</td>
				<td>{email}</td>
				<td>{age}</td>
				<td>{designation}</td>
				<td>{organisation}</td>
				<td>{username}</td>
				<td
					onClick={() =>
						likedVideoOfUser?.length &&
						setModal({
							...modal,
							modal2: true,
							userName: name,
						})
					}
				>
					<a
						className={`${
							likedVideoOfUser?.length !== 0 && `hover-underline cursor-pointer`
						}`}
					>
						{likedVideoOfUser?.length}
					</a>
				</td>
				<td
					onClick={() =>
						watchedVideoByUser?.length &&
						setModal({ ...modal, modal1: true, userName: name })
					}
				>
					<a
						className={`${
							watchedVideoByUser?.length !== 0 &&
							`hover-underline cursor-pointer`
						}`}
					>
						{watchedVideoByUser?.length}
					</a>
				</td>
			</tr>
		</>
	);
};

export default UtableRow;
