/** @format */

import { Done, HourglassBottom } from '@mui/icons-material';
import axios from 'axios';
import React from 'react';
import { useEffect, useRef, useCallback } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import { AddContentInBlog } from '../../../blogReducer/blogAction';
import { GetCategory } from '../../../redux/api';
import { TagsInput } from './TagsInput';

export const MarathiBlog = () => {
	const [profileStatus, setprofileStatus] = useState(0);
	const [ThumbnailStatus, setThumbnailStatus] = useState(0);
	const [categories, setcategories] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const blog = useSelector((state) => state.blog);
	const blogDetails = blog.blogData;
	const dispatch = useDispatch();
	const blogInfo = blog.blogData['marathiInfo'];
	var blogcontent = '';
	const [blogData, setblogData] = useState({
		title: '',
		thumbnail: '',
		author: '',
		profile: '',
		category: '',
		readTime: '',
		content: '',
	});

	const { tagData } = useSelector((state) => state.blog);
	const tags = tagData['marathiInfo']?.tags ?? [];
	useEffect(() => {
		const date = new Date();
		const newdate = date.toISOString().slice(0, 10);
		setblogData({ ...blogData, date: newdate });
	}, []);

	const editor = useRef(null);

	const [contentMode, setContentMode] = useState('pasting');

	const handleInputContentchange = (newContent) => {
		blogcontent = newContent;
	};

	const handleInputContentsave = (newContent) => {
		setblogData({ ...blogData, content: newContent });
	};

	const handleInputchange = (name) => (event) => {
		setblogData({ ...blogData, [name]: event.target.value });
	};

	//photos

	const handleProfilechange = async (e) => {
		e.preventDefault();
		setprofileStatus(2);
		const formdata = new FormData();
		formdata.append('file', e.target.files[0]);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/file',
				formdata
			);
			setblogData({ ...blogData, profile: data?.data?.link });
			setprofileStatus(1);
		} catch (error) {
			console.log(error);
		}
	};

	const handleThumbnailchange = async (e) => {
		e.preventDefault();
		setThumbnailStatus(2);
		const formdata = new FormData();
		formdata.append('file', e.target.files[0]);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/file',
				formdata
			);
			setblogData({ ...blogData, thumbnail: data?.data?.link });
			setThumbnailStatus(1);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		const getcategory = async () => {
			try {
				const data = await GetCategory();
				setcategories(data?.data?.category);
			} catch (error) {
				console.log(error);
			}
		};
		getcategory();
	}, []);

	useEffect(() => {
		dispatch(
			AddContentInBlog({
				language: 'marathiInfo',
				data: {
					title: blogData.title,
					profile: blogData.profile,
					thumbnail: blogData.thumbnail,
					category: blogData.category,
					readTime: blogData.readTime,
					content: blogData.content,
					author: blogData.author,
					tags: tags,
				},
			})
		);
	}, [blogData, tags?.length]);

	useEffect(() => {
		setblogData({
			title: blogInfo?.title,
			profile: blogDetails?.profile,
			thumbnail: blogDetails?.thumbnail,
			category: blogDetails?.category,
			readTime: blogDetails?.readTime,
			content: blogInfo?.content,
			author: blogInfo?.author,
			tags: blogInfo?.tags,
		});
		blogcontent = blogInfo?.content;
	}, []);

	const getCategory = (item) => {
		return categories.find((i) => i._id === item);
	};

	return (
		<div className='addblog-container'>
			<div className='addblog-personalDetails'>
				{/* 1st row */}

				<div className='addblog-alignRow'>
					{/* aUthor Name */}
					<div className='addblog-inputFieldDiv form-group'>
						<label className='addblog-inputLabel '>
							Author Name{' '}
							<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
						</label>
						<input
							type='text'
							name='Author'
							defaultValue={blogInfo?.author}
							placeholder='Full Name'
							className='addblog-inputField'
							onChange={handleInputchange('author')}
						/>
					</div>
					{/* Title */}
					<div className='addblog-inputFieldDiv form-group'>
						<label className='addblog-inputLabel'>
							Blog Title{' '}
							<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
						</label>
						<input
							type='text'
							name='Title'
							defaultValue={blogInfo?.title}
							placeholder='Blog Title'
							className='addblog-inputField'
							onChange={handleInputchange('title')}
						/>
					</div>
				</div>

				{/* 2nd row */}
				<div className='addblog-alignRow'>
					{/* Category */}
					<div
						className='addblog-textFieldDiv form-group'
						style={{ width: '49%' }}
					>
						<label className='addblog-inputLabel'>
							Category{' '}
							<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
						</label>
						<Form.Select
							aria-label='Select Category'
							placeholder='Title Tagling'
							className='addblog-inputField'
							onChange={handleInputchange('category')}
						>
							<option selected hidden>
								{blogDetails?.category
									? getCategory(blogDetails?.category)?.marathiInfo || ''
									: 'Select Category'}
							</option>
							{blogDetails?.category?.marathiInfo && (
								<option selected>{blogDetails?.category?.marathiInfo}</option>
							)}
							{categories?.map((category) => (
								<option value={category._id}>{category.marathiInfo}</option>
							))}
						</Form.Select>
					</div>
					{/* TimetoRead */}
					<div className='addblog-textFieldDiv'>
						<label className='addblog-inputLabel'>
							Time To Read (Minutes){' '}
							<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
						</label>
						<input
							name='minutes'
							defaultValue={blogDetails?.readTime}
							min='0'
							onChange={handleInputchange('readTime')}
							className='addblog-inputField mx-2'
							type='number'
						/>
					</div>
				</div>
				<div className='addblog-alignRow'>
					{/* Category */}
					<div className='addblog-inputFieldDiv form-group'>
						<label className='addblog-inputLabel'>Tags</label>
						<TagsInput
							selectedTags={selectedTags}
							tags={tags.length ? tags : blogInfo?.tags}
							lng={'marathiInfo'}
						/>
					</div>
					{/* TimetoRead */}
				</div>
				{/* 3rd row */}

				<div className='addproperty-alignRow d-flex align-items-end'>
					{/* Amenities */}
					<div className='addproperty-textFieldDiv'>
						<label className='addproperty-inputLabel'>Choose Profile </label>
						<input
							type='file'
							name='video'
							placeholder='Upload Video'
							className='addproperty-inputField'
							onChange={handleProfilechange}
						/>
					</div>
					<div className='d-flex flex-column align-items-center'>
						{profileStatus == 1 ? (
							<p className='text-success d-flex'>
								Success <Done />
							</p>
						) : profileStatus == 2 ? (
							<p className='text-success d-flex'>
								Wait <HourglassBottom />
							</p>
						) : null}
					</div>
				</div>
				{blogDetails?.profile && (
					<img
						src={blogDetails?.profile}
						alt='profile'
						height={200}
						width={200}
					/>
				)}

				<div className='addproperty-alignRow d-flex align-items-end'>
					{/* Amenities */}
					<div className='addproperty-textFieldDiv'>
						<label className='addproperty-inputLabel'>Choose Thumbnail </label>
						<input
							type='file'
							name='video'
							placeholder='Upload Video'
							className='addproperty-inputField'
							onChange={handleThumbnailchange}
						/>
					</div>
					<div className='d-flex flex-column align-items-center'>
						{ThumbnailStatus == 1 ? (
							<p className='text-success d-flex'>
								Success <Done />
							</p>
						) : ThumbnailStatus == 2 ? (
							<p className='text-success d-flex'>
								Wait <HourglassBottom />
							</p>
						) : null}
					</div>
				</div>
				{blogDetails?.thumbnail && (
					<img
						src={blogDetails?.thumbnail}
						alt='profile'
						height={200}
						width={200}
					/>
				)}
				{/* 5th row */}
				<div className='addblog-alignRow'>
					{/*content*/}
					<div className='addblog-textFieldDiv'>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '10px',
								fontSize: '14px',
							}}
						>
							<label style={{ cursor: 'pointer' }}>
								<input
									type='radio'
									value='pasting'
									checked={contentMode === 'pasting'}
									onChange={() => setContentMode('pasting')}
								/>
								Pasting Mode
							</label>
							<label style={{ cursor: 'pointer' }}>
								<input
									type='radio'
									value='writing'
									checked={contentMode === 'writing'}
									onChange={() => setContentMode('writing')}
								/>
								Writing Mode
							</label>
						</div>
						<label className='addblog-inputLabel'>
							Content{' '}
							<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
						</label>
						{contentMode === 'pasting' ? (
							<JoditEditor
								ref={editor}
								value={blogData?.content}
								config={{
									uploader: {
										insertImageAsBase64URI: true,
									},
								}}
								onChange={handleInputContentsave}
							/>
						) : (
							<JoditEditor
								ref={editor}
								value={blogData?.content}
								config={{
									uploader: {
										insertImageAsBase64URI: true,
									},
								}}
								onChange={handleInputContentchange}
								onBlur={() => {
									console.log('Editor lost focus', blogcontent);
									if (blogcontent !== '') {
										setblogData({ ...blogData, content: blogcontent });
									}
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
