/** @format */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { AddToPlaylist } from '../../../../redux/api';

const AAVtableRow = ({
	title,
	index,
	phone,
	query,
	videoid,
	handleAddition,
	handleRemoval,
	playlistVideos,
}) => {
	const [active, setactive] = useState(true);

	useEffect(() => {
		playlistVideos.map((item) => {
			if (item._id === videoid) {
				setactive(false);
			}
		});
	}, []);

	return (
		<>
			<tr>
				<td>{index + 1}</td>
				<td>{title}</td>
				{/* <td><button className='btn btn-outline-primary'>Add Video</button></td> */}
				{active ? (
					<td>
						<button
							onClick={() => {
								handleAddition(videoid);
								setactive(false);
							}}
							className={`btn btn-outline-primary  `}
						>
							+
						</button>
					</td>
				) : (
					<td>
						<button
							onClick={() => {
								handleRemoval(videoid);
								setactive(true);
							}}
							className={`btn btn-danger  `}
						>
							x
						</button>
					</td>
				)}
			</tr>
		</>
	);
};

export default AAVtableRow;
