import React from 'react';
const ADTableRow = ({ index, name,organisation,designation,age,email,username,phone }) => {
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{name}</td>
        <td>{email}</td>
      </tr>
    </>
  );
};

export default ADTableRow;
