/** @format */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingPage from '../utils/LoadingPage';
import addIcon from '../../images/addIcon.svg';
import searchIcon from '../../images/searchIcon.svg';
import '../../styles/newstyles/blogs.css';
import Btable from './AllBlogs/Btable';
import { GetAllBlog, getAllBlogs } from '../../redux/api';
import { AddIntialDataBlog } from '../../blogReducer/blogAction';
import { useDispatch } from 'react-redux';
import DynamicPagination from '../Pagination/Pagination';

const Blogs = () => {
	const history = useHistory();
	const [allblogData, setallblogData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchInput, setsearchInput] = useState('');
	const [filterData, setfilterData] = useState([]);
	const [page, setPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
	const dispatch = useDispatch();
	const fetchblogList = async () => {
		// setLoading(true);
		try {
			const res = await GetAllBlog(page, searchInput);
			setallblogData(res?.data?.data);
			setTotalPages(Math.ceil(res?.data?.pageLimit / 10));
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchblogList();
	}, [page, searchInput]);

	const searchItems = (searchValue) => {
		setsearchInput(searchValue);
		if (searchValue !== '') {
			let filteredData = allblogData.filter((item) => {
				return Object.values(item)
					.join('')
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			});
			setfilterData(filteredData);
		} else {
			setfilterData(allblogData);
		}
	};

	const blogNavigate = () => {
		const data = {
			category: '',
			readTime: 0,
			profile: '',
			thumbnail: '',
			englishInfo: {
				author: '',
				title: '',
				content: '',
				tags: [],
			},

			hindiInfo: {
				author: '',
				title: '',
				content: '',
				tags: [],
			},

			tamilInfo: {
				author: '',
				title: '',
				content: '',
				tags: [],
			},

			teluguInfo: {
				author: '',
				title: '',
				content: '',
				tags: [],
			},

			marathiInfo: {
				author: '',
				title: '',
				content: '',
				tags: [],
			},
			kannadInfo: {
				author: '',
				title: '',
				content: '',
				tags: [],
			},
		};
		dispatch(AddIntialDataBlog(data));
		history.push('/blog/add');
	};

	return (
		<div className='blogs-container'>
			{loading ? (
				<LoadingPage />
			) : (
				<>
					<div className='blogs-firstSection'>
						<div className='blogs-searchDiv'>
							<img src={searchIcon} alt='search' className='searchIcon' />
							<input
								type='text'
								placeholder='Search Title or Author...'
								className='blogs-searchInput'
								id='searchInput'
								value={searchInput}
								onChange={(e) => setsearchInput(e.target.value)}
							/>
						</div>
						<div className='blogs-addblogsDiv'>
							<button className='blogs-addBtn' onClick={() => blogNavigate()}>
								<img src={addIcon} alt='add' className='blogs-addIcon' />
								<span>Add Blogs</span>
							</button>
						</div>
					</div>
					<div className='blogs-tableSection'>
						{/* {searchInput.length ? ( */}
						<Btable blogData={allblogData} />
						{/* ) : (
							<Btable blogData={allblogData} />
						)} */}
					</div>
					<DynamicPagination
						totalPages={totalPages}
						setPage={setPage}
						page={page}
					/>
				</>
			)}
		</div>
	);
};
export default Blogs;
