import { NotificationAdd } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { GetNotification, postNotification } from "../../redux/api";

export const AddNotification = () => {
  const [spinn, setspinn] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [notification, setPlaylist] = useState([]);
  const [filterdnotification, setFilterdnotification] = useState({});
  const [notificationData, setnotificationData] = useState({
    englishInfo: "",
    hindiInfo: "",
    tamilInfo: "",
    teluguInfo: "",
    marathiInfo: "",
    kannadInfo: "",
    link: "",
    active: true,
  });

  useEffect(() => {
    if (params.id !== undefined) {
      Query();
    }
  }, [params.id]);

  // api call

  const Query = async () => {
    try {
      const data = await GetNotification();
      setFilterdnotification(data?.data?.notification);
    } catch (error) {
      console.log(error);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    setspinn(true);
    if (params.id !== "add") {
      const data = {
        englishInfo:
          notificationData.englishInfo || filterdnotification.englishInfo,
        link: notificationData.link || filterdnotification.link,
        hindiInfo: notificationData.hindiInfo || filterdnotification.hindiInfo,
        tamilInfo: notificationData.tamilInfo || filterdnotification.tamilInfo,
        teluguInfo:
          notificationData.teluguInfo || filterdnotification.teluguInfo,
        marathiInfo:
          notificationData.marathiInfo || filterdnotification.marathiInfo,
        kannadInfo:
          notificationData.kannadInfo || filterdnotification.kannadInfo,
        active: notificationData.active || filterdnotification.active,
      };
      try {
        const res = await postNotification(data);
        setspinn(false);
        history.push("/notifications?ticker");
      } catch (err) {
        console.log(err);
        setspinn(false);
        alert(err.message);
      }
    } else {
      try {
        const data = await postNotification(notificationData);
        setspinn(false);
        history.push("/notifications?ticker");
      } catch (error) {
        console.log(error);
        setspinn(false);
        alert("Please Fill All Required Information");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "active") {
      setnotificationData({ ...notificationData, [name]: checked });
    } else {
      setnotificationData({ ...notificationData, [name]: value });
    }
  };

  return (
    <>
      <form>
        <div className="addproperty-container mb-5">
          <div className="addproperty-personalDetails">
            {/* 1st row */}
            <div
              className="addproperty-alignRow"
              style={{ flexDirection: "column" }}
            >
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Link
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="url"
                  name="link"
                  defaultValue={filterdnotification?.link}
                  placeholder="Enter Notification Link (https://www.example.com)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (English)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.englishInfo || ""}
                  name="englishInfo"
                  placeholder="Enter Notification (English)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (Hindi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  name="hindiInfo"
                  defaultValue={filterdnotification?.hindiInfo || ""}
                  placeholder="Enter Notification in Hindi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (Marathi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.marathiInfo || ""}
                  name="marathiInfo"
                  placeholder="Enter Notification in Marathi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (Telugu)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  defaultValue={filterdnotification?.teluguInfo || ""}
                  type="text"
                  name="teluguInfo"
                  placeholder="Enter Notification in Telugu"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Notification (Tamil)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.tamilInfo || ""}
                  name="tamilInfo"
                  placeholder="Enter Notification in Tamil"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Notification (Kannad)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.kannadInfo || ""}
                  name="kanndInfo"
                  placeholder="Enter Notification in Kannad"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-3">
              <label className="addproperty-inputLabel ">
                Enable Notification
              </label>
              <input
                type="checkbox"
                name="active"
                defaultChecked={filterdnotification?.active}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              {params.id ? "Update" : "Submit"}
              {spinn ? (
                <div
                  class="spinner-border spinner-border-sm text-white mx-2"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
