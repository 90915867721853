/** @format */

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './new-components/LoginPage';
import NavSidebar from './new-components/NavSidebar';
import Allvideos from './new-components/Videos/Allvideos';
import Career from './new-components/Career/Career';
import Blogs from './new-components/Blogs/Blogs';
import AddBlogForm from './new-components/Blogs/AddBlogForm';
import EditBlogForm from './new-components/Blogs/EditBlogForm';
import AddCareerForm from './new-components/Career/AddCareerForm';
import EditCareerForm from './new-components/Career/EditCareerForm';
import TrendingLoans from './new-components/TrendingLoans/TrendingLoans';
import AddTrendingLoansForm from './new-components/TrendingLoans/AddTrendingLoansForm';
import EditTrendingLoansForm from './new-components/TrendingLoans/EditTrendingLoansForm';
import AddVideoForm from './new-components/Videos/AddVideoForm';
import EditVideoForm from './new-components/Videos/EditVideoForm';
import Requirements from './new-components/Requirements/Requirements';
import UnitDetails from './new-components/Query/Query';
import AddDeveloperForm from './new-components/Developer/AddDeveloperForm';
import EditDeveloperForm from './new-components/Developer/EditDeveloperForm';
import Dashboard from './new-components/Dashboard/Dashboard';
import User from './new-components/User/User';
import Query from './new-components/Query/Query';
import Category from './new-components/Category/Category';
import AddCategory from './new-components/Category/AddCategory';
import Playlist from './new-components/Playlist/Playlist';
import AddPlaylist from './new-components/Playlist/AddPlaylist';
import FeaturedVideos from './new-components/FeaturedVideos/FeaturedVideos';
import PlaylistVideos from './new-components/Playlist/PlaylistVideos/PlaylistVideos';
import Signup from './new-components/utils/Signup';
import ALLADDVideos from './new-components/Playlist/AddVideo/ALLADDVideos';
import AddFeaturedVideos from './new-components/FeaturedVideos/AddFeatureVideos/AddFeatureVideos';
import AdminsList from './new-components/utils/Admins/AdminsList';
import Notification from './new-components/Notifications/Notification';
import { AddNotification } from './new-components/Notifications/AddNotification';
import { NotificationMain } from './new-components/Notifications/NotificationMain';
import { AddNotificationPanel } from './new-components/Notifications/NotificationPanel/AddNotificationPanel';
import { FaqTable } from './new-components/Faq/FaqTable';
import { FaqRow } from './new-components/Faq/FaqRow';
import { Faq } from './new-components/Faq/Faq';
import { AddFaqForm } from './new-components/Faq/AddFaqForm';
import { Toaster } from 'react-hot-toast';
export const history = createHistory();

const App = () => {
	return (
		<Router history={history}>
			<Toaster />
			<Switch>
				<Route path='/' exact component={LoginPage} />
				<NavSidebar>
					<Route exact path='/users' component={User} />
					<Route path='/Query' exact component={Query} />

					<Route path='/playlist' exact component={Playlist} />
					<Route
						path='/playlist/videos/:name/:id'
						exact
						component={PlaylistVideos}
					/>
					<Route path='/playlist/add' exact component={AddPlaylist} />
					<Route path='/playlist/edit/:id' exact component={AddPlaylist} />
					<Route
						path='/playlist/addvideos/:name/:id'
						exact
						component={ALLADDVideos}
					/>

					<Route path='/category' exact component={Category} />
					<Route path='/category/add' exact component={AddCategory} />
					<Route path='/category/edit/:id' exact component={AddCategory} />

					<Route path='/featuredvideos' exact component={FeaturedVideos} />
					<Route
						path='/featuredvideos/add'
						exact
						component={AddFeaturedVideos}
					/>

					<Route path='/dashboard' exact component={Dashboard} />
					<Route exact path='/allvideos' component={Allvideos} />
					<Route path='/allvideos/add' exact component={AddVideoForm} />
					<Route path='/allvideos/edit/:id' exact component={AddVideoForm} />

					{/* <Route
            path="/property/unitdetail/add/:id"
            exact
            component={AddUnitDetailsForm}
          /> */}
					<Route
						path='/property/adddev/:id'
						exact
						component={AddDeveloperForm}
					/>
					<Route
						path='/property/editdev/:id'
						exact
						component={EditDeveloperForm}
					/>

					<Route path='/blog' exact component={Blogs} />
					<Route path='/blog/add' exact component={AddBlogForm} />
					<Route exact path='/blog/edit/:id/' component={AddBlogForm} />

					<Route path='/notifications' exact component={NotificationMain} />
					<Route path='/notifications/add' exact component={AddNotification} />
					<Route
						path='/notificationpanel/add'
						exact
						component={AddNotificationPanel}
					/>
					<Route
						path='/notifications/add/:id'
						exact
						component={AddNotification}
					/>
					<Route
						path='/notificationpanel/add/:id'
						exact
						component={AddNotificationPanel}
					/>
					<Route path='/faq' exact component={Faq} />
					<Route path='/faq/add/:id' exact component={AddFaqForm} />
					<Route path='/faq/add' exact component={AddFaqForm} />

					<Route exact path='/signup/add' component={Signup} />
					<Route exact path='/signup' component={AdminsList} />
				</NavSidebar>
			</Switch>
		</Router>
	);
};

export default App;
