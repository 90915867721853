import React,{useState,useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { AdminSignUp } from '../../redux/api';
import { useNavigate } from 'react-router';
function Signup() {
    const navigate = useNavigate();
    const [UserData, setUserData] = useState({
        name:"",
        email:"",
        password:""
    })

    const handlechange = (e)=>{
        const {name,value}=e.target
        setUserData({...UserData,[name]:value});
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        try {
            await AdminSignUp(UserData);
            toast.success("User Added")
            navigate('/signup')
            } catch (error) {
                console.log()
                if(error.response.data.code === 400){
                    toast.error(error.response.data.message);
            }

           
        }
    }
  return (
    <>
         <form>
         <Toaster/>
                <div className="addproperty-container">
                    <div className="addproperty-personalDetails">
                        {/* 1st row */}
                        <div className="addproperty-alignRow">
                            <div className="w-100 form-group">
                                <label className="addproperty-inputLabel ">
                                     Name{' '}
                                    <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    onChange={handlechange}
                                    placeholder="Full Name"
                                    className="addproperty-inputField w-100"
                                />
                            </div>
                        </div>
                        <div className="addproperty-alignRow">
                            <div className="w-100 form-group">
                                <label className="addproperty-inputLabel ">
                                     Email{' '}
                                    <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={handlechange}
                                    placeholder="Email"
                                    className="addproperty-inputField w-100"
                                />
                            </div>
                        </div>
                        <div className="addproperty-alignRow">
                            <div className="w-100 form-group">
                                <label className="addproperty-inputLabel ">
                                    Password{' '}
                                    <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
                                </label>
                                <input
                                    type="text"
                                    name="password"
                                    onChange={handlechange}
                                    placeholder="Password"
                                    className="addproperty-inputField w-100"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="addproperty-submitDetailDiv">
            <button
            onClick={handleSubmit}
              className="addproperty-submitDetailBtn"
            >
              Signup
              
            </button>
          </div>
                    </div></form>
    </>
  )
}

export default Signup