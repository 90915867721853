import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SortableHandle } from "react-sortable-hoc";

const PVtableRow = ({ title, ids, phone, query, id, handleDelete }) => {
  const RowHandler = SortableHandle((e) => (
    <a>
      <DragIndicatorIcon />
    </a>
  ));
  return (
    <>
      <tr>
        <td className="handle" style={{ cursor: "grab" }} data-id={id}>
          {/* <div className="d-flex"> */}
          <RowHandler />
          {ids + 1}
          {/* </div> */}
        </td>
        <td>{title}</td>
        {/* <td><button className='btn btn-outline-primary'>Add Video</button></td> */}
        <td>
          <button
            className="btn btn-outline-danger"
            onClick={() => handleDelete(id)}
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>
    </>
  );
};

export default PVtableRow;
