export const AddIntialDataBlog = (data) => {
  return {
    type: "ADD_INITIAL_BLOG_DATA",
    payload: data,
  };
};
export const addInitialTags = (data) => {
  return {
    type: "INITIAL_TAGS",
    payload: data,
  };
};
export const AddContentInBlog = (data) => {
  return {
    type: "ADD_CONTENT_IN_BLOG",
    payload: data,
  };
};

export const clearBlogData = (data) => {
  return {
    type: "CLEAR_SECTION_BLOG",
    payload: data,
  };
};

export const AddTags = (data) => {
  return {
    type: "ADD_TAGS",
    payload: data,
  };
};
