/** @format */

import axios from 'axios';
let token = localStorage.getItem('token');
// for live server
console.log('token', token);
const API = axios.create({
	baseURL: 'https://aspire0.herokuapp.com/',
});

const NewAPI = axios.create({
	baseURL: 'https://academyapi.samunnati.com',
	//baseURL: 'http://localhost:5000',
});

NewAPI.interceptors.request.use((config) => {
	if (token) {
		config.headers['token'] = token; // for Node.js Express back-end
	}
	return config;
});
NewAPI.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (
			error?.response?.data?.message.toLowerCase().includes('webtoken') ||
			error?.response?.data?.message.toLowerCase().includes('token') ||
			error?.response?.data?.message.toLowerCase().includes('expired') ||
			error?.response?.data?.message.toLowerCase().includes('invalid')
		) {
			localStorage.removeItem('token');
			localStorage.clear();
			window.location.replace(`/`);
			alert('Session Expired');
		}
		if (error?.response?.data?.message.toLowerCase().includes('token')) {
			localStorage.removeItem('token');
			localStorage.clear();
			window.location.replace(`/`);
			alert('Session Expired');
		}
		console.log(error.response);
		return Promise.reject(error);
	}
);

// signin
export const AdminSignIn = (data) => NewAPI.post('/api/admin/signin', data);
export const AdminSignUp = (data) => NewAPI.post('/api/admin/signup', data);
export const getAdmins = () => NewAPI.get('/api/admin');

export const GetQuery = () => NewAPI.get('/api/admin/query?queryIndex=0');
export const replyToMail = (data) => NewAPI.post('/api/admin/reply', data);
export const GetUsers = (p, search) =>
	NewAPI.get(`/api/admin/user?userIndex=${p}&search=${search}`);
export const DeleteQuery = (id) => NewAPI.delete(`/api/admin/query/${id}`);

export const PostCategory = (data) => NewAPI.post('/api/admin/category', data);
export const PostPlaylist = (data) => NewAPI.post('/api/admin/playlist', data);
export const updateOrder = (data) => NewAPI.put('/api/admin/playlist', data);
export const UpdatePlaylistCategory = (data) =>
	NewAPI.patch('/api/admin/playlist-category', data);
export const updatePlaylistVideo = (data) =>
	NewAPI.patch('/api/admin/playlist-video', data);
export const categoryOrder = (data) => NewAPI.put('/api/admin/category', data);
export const AddToPlaylist = (data) =>
	NewAPI.post('/api/admin/video/playlist', data);
export const DeleteFromPlaylist = (data) =>
	NewAPI.post('/api/admin/video/playlist', data);

export const GetCategory = () => NewAPI.get('/api/admin/category');
export const GetPlaylist = () => NewAPI.get('/api/admin/playlist');
export const GetPlaylistById = (id) =>
	NewAPI.get(`/api/user/video/playlist?playlist=${id}&videoIndex=0`);

export const GetCategoryCount = () => NewAPI.get('/api/admin/videoCount');

export const AddBlog = (data) => NewAPI.post('/api/admin/blog', data);
export const GetAllBlog = (index, search) =>
	NewAPI.get(`/api/admin/blog?blogIndex=${index}&search=${search}`);
export const getBlogById = (id) => NewAPI.get(`/api/admin/blog/${id}`);
export const DeleteBlog = (id) => NewAPI.delete(`/api/admin/blog/${id}`);
export const EditBlog = (data) => NewAPI.put(`/api/admin/blog`, data);

export const GetAllVideos = (index, limit = 10, playlist = '') =>
	NewAPI.get(
		`/api/admin/video?videoIndex=${index}&sortBy=date&limit=${limit}${playlist}`
	);

export const GetAllVideosOfPlaylist = (playlist, page, limit = 10) =>
	NewAPI.get(
		`/api/admin/video?videoIndex=${page}&playlist=${playlist}&limit=${limit}`
	);
export const playListById = (id, data) =>
	NewAPI.put(`api/admin/playlist/${id}`, data);
export const editCategoryById = (id, data) =>
	NewAPI.put(`api/admin/category/${id}`, data);

export const getSearchedData = (data) => NewAPI.get('api/admin/allSearchTerms');

export const GetAllTop = () => NewAPI.get('/api/stats/completedVideo/top50');
export const GetAllTop10 = () =>
	NewAPI.get('/api/stats/completedVideo/top10videos');
export const getUserWatchedVideos = (id, page) =>
	NewAPI.get(`/api/stats/completedVideo/user/${id}?page=${page}`);
export const getUserListOfLikedVideoes = (id, page) =>
	NewAPI.get(`/api/user/video/likedVideos?userId=${id}&page=${page}`);

export const userLikedVideoes = (id) =>
	NewAPI.get(`/api/stats/likesOfVideo/${id}`);
export const postNotification = (data) =>
	NewAPI.post(`/api/admin/notification`, data);

export const GetNotification = () => NewAPI.get(`/api/user/notification`);
export const GetFaqs = () => NewAPI.get(`/api/admin/faqs`);
export const PostFaqs = (data) => NewAPI.post(`/api/admin/faqs`, data);
export const getFaqId = (data) => NewAPI.post(`/api/admin/faqs/single`, data);
export const updateFaq = (data) => NewAPI.patch(`/api/admin/faqs`, data);
export const DeleteFaq = (data) =>
	NewAPI.delete(`/api/admin/faqs`, { data: data });
export const GetNotificationPanel = () =>
	NewAPI.get(`api/user/notificationPanel`);
export const AddNotification = (data) =>
	NewAPI.post(`api/admin/notificationPanel`, data);

export const EditNotificationById = (id, data) =>
	NewAPI.patch(`api/admin/notificationPanel/${id}`, data);
export const DeleteNotificationPanel = (id, data) =>
	NewAPI.delete(`api/admin/notificationPanel/${id}`, data);

export const getAllUserPlaylist = (lng) =>
	NewAPI.get(`api/user/video/playlists?language=${lng}`);

export const getCompletedPlaylist = (lng) =>
	NewAPI.get(`/api/stats/completedPlaylist`);
export const GetPlaylistStats = () => NewAPI.get('/api/admin/playlistStats');
export const GetTotalViews = () => NewAPI.get('/api/admin/totalViews');

export const deletePlaylist = (id) => NewAPI.delete(`api/admin/playlist/${id}`);
export const deleteCategory = (id) => NewAPI.delete(`api/admin/category/${id}`);

export const AddVideo = (data) => NewAPI.post('/api/admin/video', data);
export const GetVideoById = (id) => NewAPI.get(`/api/admin/video/${id}`);

export const GetFeatureVideo = (page) =>
	NewAPI.get(`/api/user/video/featured?videoIndex=${page}`);
export const GetFeatureVideoToAdd = (page) =>
	NewAPI.get(`/api/admin/video?videoIndex=${page}&order=-1`);

export const UpdateVideo = (data) => NewAPI.put(`/api/admin/video`, data);
export const DeleteVideo = (id) => NewAPI.delete(`/api/admin/video/${id}`);
export const publishedVideo = (id, data) =>
	NewAPI.patch(`/api/admin/video/${id}`, data);

export const AddFeatured = (data) =>
	NewAPI.post('/api/admin/video/featured', data);

// statss
export const NoOfRegisteredUser = () =>
	NewAPI.get('/api/stats/registeredUsers');
export const categoryCount = (type, category) =>
	NewAPI.get(`api/stats/categoryWiseCount?${type}=${category}`);
export const TotalHour = () => NewAPI.get('/api/stats/totalHoursOfVideo');

export const getAllProperty = () => API.get('/prop/getAllProperty');
export const addProperty = (data) => API.post('/prop/addProperty', data);

export const deleteProperty = (propid) =>
	API.post('/prop/deleteProperty', { id: propid });
export const getPropertyById = (propid) =>
	API.post('/prop/getPropertyById', { id: propid });

export const updateProperty = (data) => API.post('/prop/updateProperty', data);
export const getAllRequirements = () => API.get('/prop/getRequirements');

// UnitDetails
export const addUnitDetail = (data) => API.post('/prop/addUnitDetail', data);
export const getUnitDetailByPropertyId = (propid) =>
	API.post('/prop/getUnitDetail', { id: propid });
export const deleteUnitDetail = (id, bhk, did) =>
	API.post('/prop/deleteUnitDetail', {
		id: id,
		bhk: bhk,
		detailId: did,
	});

// Developer
export const getAllDeveloper = () => API.get('/dev/getAllDeveloper');
export const addDeveloper = (data) => API.post('/dev/addDeveloper', data);

export const deleteDeveloper = (devid) =>
	API.post('/dev/deleteDeveloper', { id: devid });

export const getDeveloperById = (devid) =>
	API.post('/dev/getDeveloperById', { id: devid });
export const updateDeveloper = (data) => API.post('/dev/updateDeveloper', data);

// Blogs

// export const getAllBlogs = () => API.get('/blog/getAllBlog');
// export const addBlog = (data) => API.post('/blog/addBlog', data);
export const deleteBlog = (blogid) =>
	API.post('/blog/deleteBlog', { id: blogid });
// export const getBlogById = (blogid) =>
//   API.post('/blog/getBlogById', { id: blogid });
export const updateBlog = (data) => NewAPI.put('/api/admin/blog', data);

//Career

export const getAllCareer = () => API.get('/cr/getAllCareer');
export const getCareerById = (blogid) =>
	API.post('/cr/getCareerById', { id: blogid });
export const addCareer = (data) => API.post('/cr/addCareer', data);

export const updateCareer = (data) => API.post('/cr/updateCareer', data);
export const deleteCareer = (blogid) =>
	API.post('/cr/deleteCareer', { id: blogid });

//featured Project

export const getAllProject = () => API.get('/proj/getAllProject');
export const getProjectById = (pid) =>
	API.post('/proj/getProjectById', { id: pid });
export const addProject = (data) => API.post('/proj/addProject', data);
export const updateProject = (data) => API.post('/proj/updateProject', data);
export const deleteProject = (projid) =>
	API.post('/proj/deleteProject', { id: projid });

//Loans

export const getAllLoan = () => API.get('/ln/getAllLoan');
export const getLoanById = (lid) => API.post('/ln/getLoanById', { id: lid });
export const addLoan = (data) => API.post('/ln/addLoan', data);
export const updateLoan = (data) => API.post('/ln/updateLoan', data);
export const deleteLoan = (lid) => API.post('/ln/deleteLoan', { id: lid });

//Contacts

export const getAllContacts = () => API.get('/cn/getAllContacts');
export const getContactsById = (lid) =>
	API.post('/cn/getContactsById', { id: lid });
export const addContacts = (data) => API.post('/cn/addContacts', data);
export const updateContacts = (data) => API.post('/cn/updateContacts', data);
export const deleteContacts = (cid) =>
	API.post('/cn/deleteContacts', { id: cid });

//Experts

export const getAllExperts = () => API.get('/cn/getAllExperts');
