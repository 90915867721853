import React, { useEffect, useRef, useState } from "react";
import "../../styles/newstyles/addPropertyForm.css";
import "./video.css";
import { useHistory, useParams } from "react-router-dom";

import LoadingPage from "../utils/LoadingPage";
import { Hindi } from "./UploadByLng/Hindi";
import { English } from "./UploadByLng/English";
import { Telugu } from "./UploadByLng/Telugu";
import { Tamil } from "./UploadByLng/Tamil";
import { ShowActive } from "../../utils";
import { Marathi } from "./UploadByLng/Marathi";
import { AddVideo, GetVideoById, UpdateVideo } from "../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import { AddData, clearData } from "../../videoReducer/videoAction";
import { Kannad } from "./UploadByLng/Kannada";
const AddVideoForm = () => {
  const isFirstRender = useRef(true);
  const history = useHistory();
  const params = useParams();
  //upload Video
  const [spinn, setspinn] = useState(false);
  const [spinn1, setspinn1] = useState(false);
  //upload Thumbnail
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(1);
  const { videoData } = useSelector((state) => state.video);
  const handlesubmit = async (e) => {
    setspinn(true);
    e.preventDefault();
    if (params.id) {
      try {
        const data = await UpdateVideo({
          ...videoData,
          _id: params.id,
        });
        setspinn(false);
        alert("Video update successfully");
        history.push("/allvideos");
        dispatch(clearData({}));
      } catch (err) {
        console.log(err);
        setspinn(false);
        alert("Video update failed: " + err.message);
      }
    } else {
      try {
        const data = await AddVideo(videoData);
        history.push("/allvideos");
        dispatch(clearData({}));
        setspinn(false);
      } catch (error) {
        console.log(error);
        setspinn(false);
      }
    }
  };

  const publish = async (e, value) => {
    e.preventDefault();
    setspinn1(true);
    const yes = window.confirm("Are you sure you want to publish this video?");
    if (!yes) {
      setspinn1(false);
      return;
    }
    if (params.id) {
      try {
        const data = await UpdateVideo({
          ...videoData,
          isPublished: value,
          _id: params.id,
        });
        setspinn1(false);
        alert("Video update successfully");
        history.push("/allvideos");
        dispatch(clearData({}));
      } catch (err) {
        console.log(err);
        setspinn1(false);
        alert("Video update failed: " + err.message);
      }
    }
  };

  const list = [
    {
      id: 1,
      title: "Hindi",
    },
    {
      id: 2,
      title: "English",
    },
    {
      id: 3,
      title: "Marathi",
    },
    {
      id: 4,
      title: "Telugu",
    },
    {
      id: 5,
      title: "Tamil",
    },
    {
      id: 6,
      title: "Kannada",
    },
  ];

  const getRendered = (state) => {
    const Screen = {
      1: <Hindi />,
      2: <English />,
      3: <Marathi />,
      4: <Telugu />,
      5: <Tamil />,
      6: <Kannad />,
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };

  const getdata = async () => {
    try {
      const data = await GetVideoById(params.id);
      dispatch(AddData(data?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (params.id) {
      getdata();
    }
  }, [params.id]);

  // playlist and category

  // const handleCategory = (e) => {
  //   setvideoData({ ...videoData, category:[ ...videoData.category,e.target.value ]});
  // }

  //calling category

  return (
    <form>
      <div className="addproperty-container">
        {loading ? (
          <LoadingPage />
        ) : (
          <div>
            <div className="tabbutton mt-5 pt-2" id="tabbutton">
              <ul className="d-flex justify-content-evenly">
                {list.map((item) => (
                  <ShowActive
                    title={item.title}
                    className="tabbutton"
                    active={selected === item.id}
                    setSelected={setSelected}
                    id={item.id}
                  />
                ))}
              </ul>
            </div>

            <div className="container">{getRendered(selected)}</div>
            <div className="d-flex justify-content-center mb-5">
              {selected > 1 && (
                <button
                  className="btn btn-primary mt-3 mx-2"
                  type="button"
                  onClick={() =>
                    setSelected(selected !== 1 ? selected - 1 : selected)
                  }
                >
                  &larr; Prev
                </button>
              )}
              <button
                className="btn btn-primary mt-3 mx-2"
                style={{ height: "51px" }}
                type="button"
                onClick={() =>
                  setSelected(selected !== 5 ? selected + 1 : selected)
                }
              >
                Next &rarr;
              </button>
              <button
                className="addproperty-submitDetailBtn mt-3"
                style={{ width: "40%" }}
                onClick={handlesubmit}
              >
                {params.id ? "Update" : "Submit All"}
                {spinn ? (
                  <div
                    style={{ width: "1rem" }}
                    class="spinner-border spinner-border-sm text-white mx-2"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
              </button>
              {params.id && (
                <button
                  className={`${
                    !videoData?.isPublished
                      ? "btn btn-success"
                      : "btn btn-danger"
                  } mt-3 mx-2`}
                  style={{ width: "30%" }}
                  onClick={(e) =>
                    publish(e, videoData?.isPublished ? false : true)
                  }
                >
                  {videoData?.isPublished ? "Unpublish" : "Publish"}
                  {spinn1 ? (
                    <div
                      style={{ width: "1rem" }}
                      class="spinner-border spinner-border-sm text-white mx-2"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default AddVideoForm;
