/** @format */

import React, { useState, useEffect } from 'react';
import { storage } from '../../../firebase';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import VideoSnapshot from 'video-snapshot';
import { Done, ForkRight, HourglassBottom } from '@mui/icons-material';
import {
	addProperty,
	AddVideo,
	GetCategory,
	GetPlaylist,
} from '../../../redux/api';
import { useDispatch, useSelector } from 'react-redux';
import { AddContent } from '../../../videoReducer/videoAction';
export const Telugu = () => {
	const [categories, setcategories] = useState([]);
	const [playlist, setplaylist] = useState([]);
	const [videoStatus, setvideoStatus] = useState(0);
	const dispatch = useDispatch();
	const [spinn, setspinn] = useState(false);
	const video = useSelector((state) => state.video);
	const videoDetails = video.videoData;
	const videoInfo = video.videoData['teluguInfo'];
	const [ThumbnailStatus, setThumbnailStatus] = useState(0);
	const [videoTime, setVideoTime] = useState(0);
	const [file1, setFile1] = useState(null);
	const [uploadVideo, setuploadVideo] = useState({
		video: '',
	});
	const [uploadThumbnail, setuploadThumbnail] = useState({
		thumbnail: '',
	});

	const [loading, setloading] = useState(true);

	const [videoData, setvideoData] = useState({
		title: '',
		uploadedBy: '',
		url: '',
		language: '',
		category: '',
		playlist: '',
		description: '',
		date: '',
		duration: '',
		thumbnail: '',
	});
	var date = new Date();
	var currentDate = date.toISOString().slice(0, 10);
	var currentTime = date.getHours() + ':' + date.getMinutes();
	const handleInputchange = (e) => {
		const { name, value } = e.target;
		setvideoData({ ...videoData, [name]: value });
	};

	const getVideoDuration = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const media = new Audio(reader.result);
				media.onloadedmetadata = () => resolve(media.duration);
			};
			reader.readAsDataURL(file);
			reader.onerror = (error) => reject(error);
		});

	const handleVideo = async (e) => {
		const duration = await getVideoDuration(e.target.files[0]);
		console.log(duration);
		setvideoData({ ...videoData, duration: parseInt(duration) });
		setuploadVideo({
			...uploadVideo,
			video: e.target.files[0],
		});
	};
	const handleVideosubmit = async (e) => {
		e.preventDefault();
		setvideoStatus(2);
		const formdata = new FormData();
		formdata.append('file', uploadVideo.video);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/file',
				formdata
			);
			setvideoData({ ...videoData, url: data?.data?.link });
			setvideoStatus(1);
		} catch (error) {
			console.log(error);
		}
	};

	const handleThumbnail = (e) => {
		setuploadThumbnail({ ...uploadThumbnail, thumbnail: e.target.files[0] });
	};

	const handleThumbnailsubmit = async (e) => {
		e.preventDefault();
		setThumbnailStatus(2);
		const formdata = new FormData();
		formdata.append('file', uploadThumbnail.thumbnail);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/other',
				formdata
			);
			setvideoData({ ...videoData, thumbnail: data?.data?.link });
			setThumbnailStatus(1);
		} catch (error) {
			console.log(error);
		}
	};
	console.log(uploadThumbnail);

	// final api
	const handlesubmit = async (e) => {
		setspinn(true);
		e.preventDefault();
		try {
			const data = await AddVideo(videoData);
			// history.push("/allvideos");
			setspinn(false);
		} catch (error) {
			console.log(error);
			setspinn(false);
		}
	};
	const handlePlaylist = (e) => {
		setvideoData({
			...videoData,
			playlist: [...videoData.playlist, e.target.value],
		});
	};
	const getcategory = async () => {
		try {
			const data = await GetCategory();
			setcategories(data?.data?.category);
			setloading(false);
		} catch (error) {
			console.log(error);
			setloading(false);
		}
	};

	const getPlaylist = async () => {
		try {
			const data = await GetPlaylist();
			setplaylist(data?.data?.playlist);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getcategory();
		getPlaylist();
		setvideoData({ ...videoData, date: currentDate });
	}, []);

	useEffect(() => {
		if (
			videoData.title ||
			videoData.url ||
			videoData.thumbnail ||
			videoData.category ||
			videoData.playlist ||
			videoData.description ||
			videoData.category ||
			videoData.uploadedBy ||
			videoData.duration ||
			videoData.date
		) {
			dispatch(
				AddContent({
					language: 'teluguInfo',
					data: {
						title: videoData.title || videoInfo?.title,
						url: videoData.url || videoInfo?.url,
						thumbnail: videoData.thumbnail || videoInfo?.thumbnail,
						category: videoData.category || videoDetails?.category,
						playlist: videoData.playlist || videoDetails?.playlist,
						description: videoData.description || videoInfo?.description,
						uploadedBy: videoData.uploadedBy || videoDetails.uploadedBy,
						date: videoData.date || videoDetails.date,
						duration: videoData.duration || videoDetails.duration,
						order: videoDetails.order,
					},
				})
			);
		}
	}, [videoData]);

	const getLastPlaylist = (item) => {
		return playlist.find((i) => i._id === item);
	};
	const getCategory = (item) => {
		return categories.find((i) => i._id === item);
	};

	function settime() {
		var video = document.getElementById('videoPreview');
		video?.play();
		const interval = setInterval(function () {
			setVideoTime(video?.currentTime);
		}, 1000);
	}
	useEffect(() => {
		const fetchVideo = async () => {
			let response = await fetch(videoDetails['teluguInfo']['url']);
			let data = await response.blob();
			let metadata = {
				type: 'video/mp4',
			};
			let file2 = new File([data], 'video.mp4', metadata);
			setFile1(file2);
		};
		if (videoDetails['teluguInfo']['url']) {
			fetchVideo();
		}
		settime();
	}, [videoDetails]);
	function dataURLtoFile(dataurl, filename) {
		fetch(dataurl)
			.then((res) => res.blob())
			.then((blob) => {
				return new File([blob], filename, { type: 'image/png' });
			});
	}
	const takeScreenShot = async () => {
		if (file1.name) {
			const snapshoter = new VideoSnapshot(file1);
			const previewSrc = await snapshoter.takeSnapshot(videoTime);
			setThumbnailStatus(2);
			const formdata = new FormData();
			var file = dataURLtoFile(
				previewSrc,
				videoTime + Math.ceil(Math.random(19332239)).toString() + '.png'
			);
			formdata.append('file', file);
			try {
				const data = await axios.post(
					'https://uploader.viosa.in/api/v2/samunnati/upload/other',
					formdata
				);
				setvideoData({ ...videoData, thumbnail: data?.data?.link });
				setThumbnailStatus(1);
			} catch (error) {
				console.log(error);
			}
			setThumbnailStatus(1);
		}
	};

	return (
		<div className='addproperty-personalDetails'>
			<div className='addproperty-alignRow'>
				<div className='addproperty-inputFieldDiv form-group'>
					<label className='addproperty-inputLabel '>
						Video Title{' '}
						<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>
					<input
						type='text'
						defaultValue={videoInfo?.title}
						name='title'
						placeholder='Video Title'
						className='addproperty-inputField'
						onChange={handleInputchange}
					/>
				</div>
				<div className='addproperty-inputFieldDiv form-group'>
					<label className='addproperty-inputLabel'>
						Uploaded By
						<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>
					<input
						defaultValue={videoDetails?.uploadedBy || 'Samunnati'}
						type='text'
						name='uploadedBy'
						placeholder='Uploaded By'
						className='addproperty-inputField'
						onChange={handleInputchange}
					/>
				</div>
			</div>

			{/* 3rd row */}

			<div className='addproperty-alignRow'>
				<div className='addproperty-inputFieldDiv'>
					<label className='addproperty-inputLabel'>
						Date <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>
					<input
						type='date'
						name='date'
						placeholder='Date'
						value={currentDate}
						defaultValue={videoDetails?.date}
						className='addproperty-inputField'
						onChange={handleInputchange}
					/>
				</div>
				<div className='addproperty-inputFieldDiv'>
					<label className='addproperty-inputLabel'>
						Length (in seconds)
						<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>
					<input
						type='number'
						name='duration'
						min='0'
						defaultValue={videoDetails?.duration}
						placeholder={videoDetails?.duration}
						className='addproperty-inputField'
						onChange={handleInputchange}
					/>
				</div>
			</div>

			<div className='addproperty-alignRow d-flex align-items-end'>
				{/* Amenities */}
				<div className='addproperty-textFieldDiv me-5'>
					<label className='addproperty-inputLabel'>
						Choose Video{' '}
						<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>
					<input
						type='file'
						accept='video/mp4,video/mkv,video/avi'
						placeholder='Upload Video'
						className='addproperty-inputField'
						onChange={handleVideo}
					/>
				</div>

				<div className='d-flex flex-column align-items-center'>
					{videoStatus == 1 ? (
						<p className='text-success d-flex'>
							Success <Done />
						</p>
					) : videoStatus == 2 ? (
						<p className='text-success d-flex'>
							Wait <HourglassBottom />
						</p>
					) : null}
					<button
						className={`btn btn-primary ${
							uploadVideo.video ? null : 'disabled'
						} `}
						onClick={handleVideosubmit}
					>
						Upload
					</button>
				</div>
			</div>
			<div>
				{videoInfo?.url ? (
					<video
						src={videoInfo?.url}
						controls
						id='videoPreview'
						style={{ width: '400px', height: '300px' }}
					></video>
				) : null}
			</div>
			{videoInfo?.url && (
				<button
					type='button'
					className='btn-success mt-2'
					style={{ height: '40px', background: '#81C060', border: 'none' }}
					onClick={(e) => takeScreenShot()}
				>
					Take Screenshot
				</button>
			)}
			<div className='addproperty-alignRow d-flex align-items-end'>
				{/* Amenities */}
				<div className='addproperty-textFieldDiv me-5'>
					<label className='addproperty-inputLabel'>
						Choose Thumbnail{' '}
						<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>
					<input
						type='file'
						name='video'
						placeholder='Upload Video'
						className='addproperty-inputField'
						onChange={handleThumbnail}
					/>
				</div>
				<div className='d-flex flex-column align-items-center'>
					{ThumbnailStatus == 1 ? (
						<p className='text-success d-flex'>
							Success <Done />
						</p>
					) : ThumbnailStatus == 2 ? (
						<p className='text-success d-flex'>
							Wait <HourglassBottom />
						</p>
					) : null}
					<button
						className={`btn btn-primary ${
							uploadThumbnail.thumbnail ? null : 'disabled'
						} `}
						onClick={handleThumbnailsubmit}
					>
						Upload
					</button>
				</div>
			</div>
			<div>
				{videoInfo?.thumbnail ? (
					<img src={videoInfo?.thumbnail} width='200' height='200' />
				) : null}
			</div>
			{/* 6th row */}
			<div className='addproperty-alignRow'>
				{/* Amenities */}
				<div className='addproperty-textFieldDiv'>
					<label className='addproperty-inputLabel'>
						Categories{' '}
						<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
					</label>

					<Form.Select
						aria-label='Select Category'
						placeholder='Title Tagling'
						name='category'
						className='addblog-inputField'
						onChange={handleInputchange}
					>
						<option selected hidden>
							{videoDetails?.category
								? getCategory(videoDetails?.category)?.teluguInfo || ''
								: 'Select Category'}
						</option>
						{videoDetails.category?.teluguInfo && (
							<option selected>{videoDetails.category?.teluguInfo}</option>
						)}
						{categories?.map((item, index) => {
							return (
								<option key={index} value={item._id}>
									{item.teluguInfo}
								</option>
							);
						})}
					</Form.Select>
				</div>

				<div className='addproperty-textFieldDiv ms-5'>
					<label className='addproperty-inputLabel'>Playlist </label>

					<Form.Select
						name='playlist'
						aria-label='Select Playlist'
						placeholder='Title Tagling'
						className='addblog-inputField'
						onChange={handleInputchange}
					>
						<option selected hidden>
							{videoDetails?.playlist
								? getLastPlaylist(videoDetails?.playlist)?.teluguInfo || ''
								: 'Select Playlist'}
						</option>
						{videoDetails?.playlist && (
							<option>{videoDetails?.playlist?.teluguInfo}</option>
						)}
						{playlist?.map((item, index) => {
							return (
								<option key={index} value={item._id}>
									{item?.teluguInfo}
								</option>
							);
						})}
					</Form.Select>
				</div>
			</div>

			{/* 7th row */}
			<div className='addproperty-alignRow'>
				{/*Description*/}
				<div className='addproperty-textFieldDiv'>
					<label className='addproperty-inputLabel'>Description </label>
					<textarea
						className='addproperty-textField'
						onChange={handleInputchange}
						name='description'
						defaultValue={videoInfo?.description}
						placeholder='Video Description'
					></textarea>
				</div>
			</div>
		</div>
	);
};
