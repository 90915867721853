import React, { useState, useEffect } from "react";
import { DeleteQuery } from "../../../redux/api";
import "../../../styles/newstyles/table.css";
import UDtableRow from "./QtableRow";
import toast, { Toaster } from "react-hot-toast";

const Qtable = ({ NotifyData }) => {
  const [query, setquery] = useState([]);

  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        const newarr = query.filter((item) => item._id != id);
        await DeleteQuery(id);
        setquery(newarr);
        toast.success("query Deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setquery(NotifyData);
  }, [NotifyData]);
  return (
    <>
      {NotifyData ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <Toaster />
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Query</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {query.map((item, index) => {
                return (
                  <UDtableRow
                    key={index}
                    index={index}
                    id={item._id}
                    name={item.name}
                    phone={item.phone}
                    email={item.email}
                    handleDelete={handleDelete}
                    query={item.query}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};

export default Qtable;
