/** @format */

import React, { useEffect, useRef, useState } from 'react';
import '../../styles/newstyles/addPropertyForm.css';
import { useHistory } from 'react-router-dom';
import {
	addProperty,
	AddVideo,
	GetCategory,
	GetVideoById,
	UpdateVideo,
} from '../../redux/api';
import { storage } from '../../firebase';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import LoadingPage from '../utils/LoadingPage';
import { useParams } from 'react-router-dom';
import { Done, ForkRight, HourglassBottom } from '@mui/icons-material';

const EditVideoForm = () => {
	const isFirstRender = useRef(true);
	const history = useHistory();
	const param = useParams();

	const [spinn, setspinn] = useState(false);
	const [loading, setloading] = useState(true);
	const [categories, setcategories] = useState([]);
	const [playlist, setplaylist] = useState([]);
	const [videoStatus, setvideoStatus] = useState(0);
	const [ThumbnailStatus, setThumbnailStatus] = useState(0);

	const [uploadVideo, setuploadVideo] = useState({
		video: '',
	});
	const [uploadThumbnail, setuploadThumbnail] = useState({
		thumbnail: '',
	});

	const [SingleVideoData, setSingleVideoData] = useState();

	const [videoData, setvideoData] = useState({
		title: '',
		uploadedBy: '',
		url: '',
		language: '',
		category: '',
		playlist: '',
		description: '',
		date: '',
		duration: '',
		thumbnail: '',
	});
	var date = new Date();
	var currentDate = date.toISOString().slice(0, 10);

	const handleInputchange = (e) => {
		const { name, value } = e.target;
		setvideoData({ ...videoData, [name]: value });
	};

	//upload Video

	const handleVideo = (e) => {
		setuploadVideo({ ...uploadVideo, video: e.target.files[0] });
	};

	const handleVideosubmit = async (e) => {
		e.preventDefault();
		setvideoStatus(2);
		const formdata = new FormData();
		formdata.append('file', uploadVideo.video);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/file',
				formdata
			);
			setvideoData({ ...videoData, url: data?.data?.link });
			setvideoStatus(1);
		} catch (error) {
			console.log(error);
		}
	};

	//upload Thumbnail

	const handleThumbnail = (e) => {
		setuploadThumbnail({ ...uploadThumbnail, thumbnail: e.target.files[0] });
	};

	const handleThumbnailsubmit = async (e) => {
		e.preventDefault();
		setThumbnailStatus(2);
		const formdata = new FormData();
		formdata.append('file', uploadThumbnail.thumbnail);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/other',
				formdata
			);
			setvideoData({ ...videoData, thumbnail: data?.data?.link });
			setThumbnailStatus(1);
		} catch (error) {
			console.log(error);
		}
	};

	// final api
	const handlesubmit = async (e) => {
		e.preventDefault();
		try {
			const data = await AddVideo(videoData);
			history.push('/allvideos');
		} catch (error) {
			console.log(error);
		}
	};

	// playlist and category

	// const handleCategory = (e) => {
	//   setvideoData({ ...videoData, category:[ ...videoData.category,e.target.value ]});
	// }
	const handlePlaylist = (e) => {
		setvideoData({
			...videoData,
			playlist: [...videoData.playlist, e.target.value],
		});
	};

	//calling category

	const getcategory = async () => {
		try {
			const data = await GetCategory();
			setcategories(data?.data?.data[0]?.category);
			setplaylist(data?.data?.data[0]?.playlist);
			setloading(false);
		} catch (error) {
			console.log(error);
			setloading(false);
		}
	};

	useEffect(() => {
		getcategory();
		setvideoData({ ...videoData, date: currentDate });
	}, []);

	//getting data
	console.log(videoData);

	const getdata = async () => {
		try {
			const data = await GetVideoById(param.id);
			console.log(data);
			setSingleVideoData(data?.data?.data);
			setloading(false);
			setvideoData(data?.data?.data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getdata();
	}, []);

	const handleUpdate = async (e) => {
		e.preventDefault();
		setspinn(true);
		try {
			const data = await UpdateVideo(videoData);
			console.log(data);
			history.push('/allvideos');
			setspinn(false);
		} catch (error) {
			setloading(false);
			setspinn(false);

			console.log(error);
		}
	};

	return (
		<form>
			<div className='addproperty-container'>
				{loading ? (
					<LoadingPage />
				) : (
					<div className='addproperty-personalDetails'>
						{/* 1st row */}
						<div className='addproperty-alignRow'>
							<div className='addproperty-inputFieldDiv form-group'>
								<label className='addproperty-inputLabel '>
									Video Title{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>
								<input
									type='text'
									name='title'
									defaultValue={SingleVideoData?.title}
									placeholder='Video Title'
									className='addproperty-inputField'
									onChange={handleInputchange}
								/>
							</div>
							<div className='addproperty-inputFieldDiv form-group'>
								<label className='addproperty-inputLabel'>
									Uploaded By
									<span style={{ color: 'red', fontSize: '1.2rem' }}>
										*
									</span>{' '}
								</label>
								<input
									type='text'
									name='uploadedBy'
									defaultValue={SingleVideoData?.uploadedBy}
									placeholder='Uploaded By'
									className='addproperty-inputField'
									onChange={handleInputchange}
								/>
							</div>
						</div>

						{/* 3rd row */}

						<div className='addproperty-alignRow'>
							<div className='addproperty-inputFieldDiv'>
								<label className='addproperty-inputLabel'>
									Date{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>
								<input
									type='date'
									name='date'
									placeholder='Date'
									defaultValue={SingleVideoData?.date}
									className='addproperty-inputField'
									onChange={handleInputchange}
								/>
							</div>

							<div className='addproperty-inputFieldDiv'>
								<label className='addproperty-inputLabel'>
									Length{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>
								<input
									type='number'
									name='duration'
									min='0'
									placeholder='Length'
									defaultValue={SingleVideoData?.duration}
									className='addproperty-inputField'
									onChange={handleInputchange}
								/>
							</div>
						</div>

						<div className='addproperty-alignRow d-flex align-items-end'>
							<div className='addproperty-textFieldDiv '>
								<label className='addproperty-inputLabel'>
									Language{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>
								<Form.Select
									aria-label='Select Category'
									className='addblog-inputField'
									onChange={handleInputchange}
								>
									<option>{SingleVideoData?.language}</option>
									<option value={'English'}>English</option>
									<option value={'Hindi'}>Hindi</option>
									<option value={'Tamil'}>Tamil</option>
									<option value={'Marathi'}>Marathi</option>
									<option value={'Urdu'}>Urdu</option>
									<option value={'Telugu'}>Telugu</option>
								</Form.Select>
							</div>
						</div>

						<div className='addproperty-alignRow d-flex align-items-end'>
							{/* Amenities */}
							<div className='addproperty-textFieldDiv me-5'>
								<label className='addproperty-inputLabel'>
									Choose Video{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>
								<input
									type='file'
									name='video'
									placeholder='Upload Video'
									className='addproperty-inputField'
									onChange={handleVideo}
								/>
							</div>

							<div className='d-flex flex-column align-items-center'>
								{videoStatus == 1 ? (
									<p className='text-success d-flex'>
										Success <Done />
									</p>
								) : videoStatus == 2 ? (
									<p className='text-success d-flex'>
										Wait <HourglassBottom />
									</p>
								) : null}
								<button
									className={`btn btn-primary ${
										uploadVideo.video ? null : 'disabled'
									} `}
									onClick={handleVideosubmit}
								>
									Upload
								</button>
							</div>
						</div>

						<div className='addproperty-alignRow d-flex align-items-end'>
							{/* Amenities */}
							<div className='addproperty-textFieldDiv me-5'>
								<label className='addproperty-inputLabel'>
									Choose Thumbnail{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>
								<input
									type='file'
									name='video'
									placeholder='Upload Video'
									className='addproperty-inputField'
									onChange={handleThumbnail}
								/>
							</div>
							<div className='d-flex flex-column align-items-center'>
								{ThumbnailStatus == 1 ? (
									<p className='text-success d-flex'>
										Success <Done />
									</p>
								) : ThumbnailStatus == 2 ? (
									<p className='text-success d-flex'>
										Wait <HourglassBottom />
									</p>
								) : null}
								<button
									className={`btn btn-primary ${
										uploadThumbnail.thumbnail ? null : 'disabled'
									} `}
									onClick={handleThumbnailsubmit}
								>
									Upload
								</button>
							</div>
						</div>
						<img
							height={120}
							width={150}
							src={SingleVideoData?.thumbnail}
							className='my-3'
							alt='No Thumbnail'
						/>

						{/* 6th row */}
						<div className='addproperty-alignRow'>
							{/* Amenities */}
							<div className='addproperty-textFieldDiv'>
								<label className='addproperty-inputLabel'>
									Categories{' '}
									<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
								</label>

								<Form.Select
									aria-label='Select Category'
									placeholder='Title Tagling'
									className='addblog-inputField'
									name='category'
									onChange={handleInputchange}
								>
									<option style={{ color: 'yellowgreen' }}>
										{' '}
										~{SingleVideoData?.category}~
									</option>
									{categories?.map((item, index) => {
										return (
											<option key={index} value={item}>
												{item}
											</option>
										);
									})}
								</Form.Select>
							</div>

							<div className='addproperty-textFieldDiv ms-5'>
								<label className='addproperty-inputLabel'>Playlist </label>

								<Form.Select
									aria-label='Select Playlist'
									placeholder='Title Tagling'
									className='addblog-inputField'
									onChange={handleInputchange}
									name='playlist'
								>
									<option>~{SingleVideoData?.playlist}~</option>
									{playlist?.map((item, index) => {
										return (
											<option key={index} value={item}>
												{item}
											</option>
										);
									})}
								</Form.Select>
							</div>
						</div>

						{/* 7th row */}
						<div className='addproperty-alignRow'>
							{/*Description*/}
							<div className='addproperty-textFieldDiv'>
								<label className='addproperty-inputLabel'>Description </label>
								<textarea
									className='addproperty-textField'
									onChange={handleInputchange}
									defaultValue={SingleVideoData?.description}
									name='description'
									placeholder='Video Description'
								></textarea>
							</div>
						</div>

						<div className='addproperty-submitDetailDiv'>
							<button
								className='addproperty-submitDetailBtn'
								onClick={handleUpdate}
							>
								Update Video
								{spinn ? (
									<div
										class='spinner-border spinner-border-sm text-white mx-2'
										role='status'
									>
										<span class='visually-hidden'>Loading...</span>
									</div>
								) : (
									''
								)}
							</button>
						</div>
					</div>
				)}
			</div>
		</form>
	);
};

export default EditVideoForm;
