/** @format */

import React, { useEffect, useRef, useState } from 'react';
import '../../styles/newstyles/addBlogForm.css';
import { useParams, useHistory } from 'react-router-dom';
import { EditBlog, getBlogById, updateBlog } from '../../redux/api';
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import axios from 'axios';
import LoadingPage from '../../new-components/utils/LoadingPage';
import ReactQuill from 'react-quill';
import JoditEditor from 'jodit-react';
import Form from 'react-bootstrap/Form';
import { Done, ForkRight, HourglassBottom } from '@mui/icons-material';

const EditBlogForm = () => {
	const history = useHistory();
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const isFirstRender = useRef(true);
	const [spinn, setspinn] = useState(false);
	const [profileStatus, setprofileStatus] = useState(0);
	const [ThumbnailStatus, setThumbnailStatus] = useState(0);
	const editor = useRef(null);

	const [blogData, setblogData] = useState({
		title: '',
		thumbnail: '',
		author: '',
		profile: '',
		category: ['new'],
		readTime: '',
		tags: ['new'], //change later to array
		content: '',
		date: '',
	});

	useEffect(() => {
		const date = new Date();
		const newdate = date.toISOString().slice(0, 10);
		setblogData({ ...blogData, date: newdate });
	}, []);

	const handleInputContentchange = (value) => {
		setblogData({ ...blogData, content: value });
	};
	const handleInputchange = (name) => (event) => {
		setblogData({ ...blogData, [name]: event.target.value });
	};

	//photos

	const handleProfilechange = async (e) => {
		e.preventDefault();
		setprofileStatus(2);
		const formdata = new FormData();
		formdata.append('file', e.target.files[0]);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/file',
				formdata
			);
			setblogData({ ...blogData, profile: data?.data?.link });
			setprofileStatus(1);
		} catch (error) {
			console.log(error);
		}
	};

	const handleThumbnailchange = async (e) => {
		e.preventDefault();
		setThumbnailStatus(2);
		const formdata = new FormData();
		formdata.append('file', e.target.files[0]);
		try {
			const data = await axios.post(
				'https://uploader.viosa.in/api/v2/samunnati/upload/file',
				formdata
			);
			setblogData({ ...blogData, thumbnail: data?.data?.link });
			setThumbnailStatus(1);
		} catch (error) {
			console.log(error);
		}
	};

	//photos submit

	// form submit

	const handlesubmit = async (e) => {
		setspinn(true);
		e.preventDefault();
		try {
			await EditBlog(blogData);
			history.push('/blog');
			setspinn(false);
		} catch (error) {
			console.log(error);
			setspinn(false);
		}
	};

	//getdata

	const getBlogData = async () => {
		try {
			const data = await getBlogById(id);
			setblogData(data?.data?.data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	useEffect(() => {
		if (id) {
			getBlogData();
		}
	}, [id]);

	return (
		<form>
			<div className='addblog-container'>
				{loading ? (
					<LoadingPage />
				) : (
					<div className='addblog-container'>
						<div className='addblog-personalDetails'>
							{/* 1st row */}

							<div className='addblog-alignRow'>
								{/* aUthor Name */}
								<div className='addblog-inputFieldDiv form-group'>
									<label className='addblog-inputLabel '>
										Author Name{' '}
										<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
									</label>
									<input
										type='text'
										name='Author'
										placeholder='Full Name'
										defaultValue={
											blogData?.englishInfo && blogData?.englishInfo?.author
										}
										className='addblog-inputField'
										onChange={handleInputchange('author')}
									/>
								</div>
								{/* Title */}
								<div className='addblog-inputFieldDiv form-group'>
									<label className='addblog-inputLabel'>
										Blog Title{' '}
										<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
									</label>
									<input
										type='text'
										defaultValue={blogData?.englishInfo?.title}
										name='Title'
										placeholder='Blog Title'
										className='addblog-inputField'
										onChange={handleInputchange('title')}
									/>
								</div>
							</div>

							{/* 2nd row */}
							<div className='addblog-alignRow'>
								{/* Category */}
								{/* <div className="addblog-inputFieldDiv form-group">
              <label className="addblog-inputLabel">
                Category{' '}
              </label>
              <Form.Select
                aria-label="Select Category"
                id={error.category ? 'red-border' : ''}
                placeholder="Title Tagling"
                className="addblog-inputField"
                onChange={handleInputchange('category')}
              >
                <option>Select Category</option>
                <option value="Knowledge Seriess">Knowledge Series</option>
                <option value="News & Updates">News & Updates</option>
                <option value="Locality Bytes">Locality Bytes</option>
                <option value="Others">Others</option>
              </Form.Select>
            </div> */}
								{/* TimetoRead */}
								<div className='addblog-textFieldDiv'>
									<label className='addblog-inputLabel'>
										Time To Read (Minutes){' '}
										<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
									</label>
									<input
										name='minutes'
										min='0'
										defaultValue={blogData?.readTime}
										onChange={handleInputchange('readTime')}
										className='addblog-inputField'
										type='number'
									/>
								</div>
							</div>
							{/* 3rd row */}

							<div className='addproperty-alignRow d-flex align-items-end'>
								{/* Amenities */}
								<div className='addproperty-textFieldDiv me-5'>
									<label className='addproperty-inputLabel'>
										Choose Profile{' '}
										{/* <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '} */}
									</label>
									<input
										type='file'
										name='video'
										placeholder='Upload Video'
										className='addproperty-inputField'
										onChange={handleProfilechange}
									/>
								</div>
								<div className='d-flex flex-column align-items-center'>
									{profileStatus == 1 ? (
										<p className='text-success d-flex'>
											Success <Done />
										</p>
									) : profileStatus == 2 ? (
										<p className='text-success d-flex'>
											Wait <HourglassBottom />
										</p>
									) : null}
								</div>
							</div>

							<img
								src={blogData?.profile}
								height={100}
								width={150}
								className='my-3'
								alt='thumbnail'
							/>

							<div className='addproperty-alignRow d-flex align-items-end'>
								{/* Amenities */}
								<div className='addproperty-textFieldDiv me-5'>
									<label className='addproperty-inputLabel'>
										Choose Thumbnail{' '}
										{/* <span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '} */}
									</label>
									<input
										type='file'
										name='video'
										placeholder='Upload Video'
										className='addproperty-inputField'
										onChange={handleThumbnailchange}
									/>
								</div>
								<div className='d-flex flex-column align-items-center'>
									{ThumbnailStatus == 1 ? (
										<p className='text-success d-flex'>
											Success <Done />
										</p>
									) : ThumbnailStatus == 2 ? (
										<p className='text-success d-flex'>
											Wait <HourglassBottom />
										</p>
									) : null}
								</div>
							</div>
							<img
								src={blogData?.thumbnail}
								height={100}
								width={150}
								className='my-3'
								alt='thumbnail'
							/>

							{/* 5th row */}
							<div className='addblog-alignRow'>
								{/*content*/}
								<div className='addblog-textFieldDiv'>
									<label className='addblog-inputLabel'>
										Content{' '}
										<span style={{ color: 'red', fontSize: '1.2rem' }}>*</span>{' '}
									</label>
									{/* <ReactQuill
										className='addblog-textField'
										placeholder='Add Blog Content here'
										modules={EditBlogForm.modules}
										defaultValue={blogData?.englishInfo?.content || ''}
										formats={EditBlogForm.formats}
										theme='snow'
										onChange={(content, delta, source, editor) => {
											setblogData({ ...blogData, content: editor.getHTML() });
										}}
									/> */}
								</div>
							</div>

							<div className='addblog-submitDetailDiv'>
								<button
									className='addblog-submitDetailBtn'
									onClick={handlesubmit}
								>
									Update Blog
									{spinn ? (
										<div
											class='spinner-border spinner-border-sm text-white mx-2'
											role='status'
										>
											<span class='visually-hidden'>Loading...</span>
										</div>
									) : (
										''
									)}
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</form>
	);
};

export default EditBlogForm;
