import React, { useEffect, useState } from "react";
import { editCategoryById, GetCategory, PostCategory } from "../../redux/api";
import translate from "translate";
import { useHistory, useParams } from "react-router-dom";
import { videoKey } from "../Dashboard/helper";

function AddCategory() {
  const [spinn, setspinn] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [category, setPlaylist] = useState([]);
  const [filterdCategory, setFilterdCategory] = useState({});
  const [hindi, setHindi] = useState("");
  const [marathi, setMarathi] = useState("");
  const [telugu, setTelugu] = useState("");
  const [tamil, setTamil] = useState("");
  const [kannad, setKannad] = useState("");
  const [categoryData, setCategoryData] = useState({
    name: "",
    hindiInfo: "",
    tamilInfo: "",
    teluguInfo: "",
    marathiInfo: "",
    kannadInfo: "",
  });
  const translateIntoLng = async (key, value) => {
    for (let i = 1; i < 6; i++) {
      if (i === 1) {
        const data = await translate(value, { to: "hi" });
        setHindi(data);
      }
      if (i === 2) {
        const data = await translate(value, { to: "te" });
        setTelugu(data);
      }
      if (i === 3) {
        const data = await translate(value, { to: "mr" });
        setMarathi(data);
      }
      if (i === 4) {
        const data = await translate(value, { to: "ta" });
        setTamil(data);
      }
      if (i === 5) {
        const data = await translate(value, { to: "kn" });
        setKannad(data);
      }
    }
  };

  useEffect(() => {
    if (hindi && telugu && marathi && tamil) {
      setCategoryData({
        ...categoryData,
        hindiInfo: hindi,
        tamilInfo: tamil,
        teluguInfo: telugu,
        marathiInfo: marathi,
        kannadInfo: kannad,
      });
      setFilterdCategory({
        ...filterdCategory,
        hindiInfo: hindi,
        tamilInfo: tamil,
        teluguInfo: telugu,
        marathiInfo: marathi,
        kannadInfo: kannad,
        name: categoryData.name,
      });
    }
  }, [hindi, marathi, tamil, telugu, kannad]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setspinn(true);
    if (params.id) {
      const data = {
        name: categoryData.name || filterdCategory.name,
        hindiInfo: categoryData.hindiInfo || filterdCategory.hindiInfo,
        tamilInfo: categoryData.tamilInfo || filterdCategory.tamilInfo,
        teluguInfo: categoryData.teluguInfo || filterdCategory.teluguInfo,
        marathiInfo: categoryData.marathiInfo || filterdCategory.marathiInfo,
        kannadInfo: categoryData.kannadInfo || filterdCategory.kannadInfo,
      };
      try {
        const res = await editCategoryById(params.id, data);
        setspinn(false);
        history.push("/category");
      } catch (err) {
        console.log(err);
        setspinn(false);
        alert(err.message);
      }
    } else {
      try {
        const data = await PostCategory(categoryData);
        setspinn(false);
        history.push("/category");
      } catch (error) {
        console.log(error);
        setspinn(false);
        alert("Please Fill All Required Information");
      }
    }
  };
  useEffect(() => {
    const playlistFetch = async () => {
      try {
        const data = await GetCategory();
        setPlaylist(data?.data?.category);
      } catch (error) {
        console.log(error);
      }
    };
    playlistFetch();
  }, []);

  useEffect(() => {
    if (category.length) {
      const filteredPlaylist = category.find((item) => item._id === params.id);
      setFilterdCategory(filteredPlaylist);
    }
  }, [params.id, category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      translateIntoLng("englishInfo", value);
      setCategoryData({ ...categoryData, name: value });
    }
    if (name === "hindiInfo") {
      translateIntoLng("hindiInfo");
    }
    if (name === "tamilInfo") {
      translateIntoLng("tamilInfo");
    }
  };
  // console.log(categoryData);

  return (
    <>
      <form>
        <div className="addproperty-container mb-5">
          <div className="addproperty-personalDetails">
            {/* 1st row */}
            <div
              className="addproperty-alignRow"
              style={{ flexDirection: "column" }}
            >
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Category (English)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={filterdCategory?.name || categoryData.name}
                  name="name"
                  placeholder="Enter Category Name in English"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Category (Hindi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  name="hindiInfo"
                  defaultValue={
                    filterdCategory?.hindiInfo || categoryData.hindiInfo
                  }
                  placeholder="Enter Category Name in Hindi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Category (Marathi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={
                    filterdCategory?.marathiInfo || categoryData.marathiInfo
                  }
                  name="marathiInfo"
                  placeholder="Enter Category Name in Marathi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Category (Telugu)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={
                    filterdCategory?.teluguInfo || categoryData.teluguInfo
                  }
                  name="teluguInfo"
                  placeholder="Enter Category Name in Telugu"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Category (Tamil)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  name="tamilInfo"
                  defaultValue={
                    filterdCategory?.tamilInfo || categoryData.tamilInfo
                  }
                  placeholder="Enter Category Name in Tamil"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Category (Kannad)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  name="kannadInfo"
                  defaultValue={
                    filterdCategory?.kannadInfo || categoryData.kannadInfo
                  }
                  placeholder="Enter Category Name in Kannad"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              {params.id ? "Update" : "Submit"}
              {spinn ? (
                <div
                  class="spinner-border spinner-border-sm text-white mx-2"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddCategory;
