import React, { useState, useEffect } from "react";
// import { DeleteQuery } from "../../../redux/api";
// import "../../../styles/newstyles/table.css";
import NtableRow from "./NtableRow";
import toast, { Toaster } from "react-hot-toast";
import { DeleteNotificationPanel } from "../../../redux/api";

const Ntable = ({ QueryData }) => {
  const [query, setquery] = useState([]);

  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        const newarr = query.filter((item) => item._id !== id);
        await DeleteNotificationPanel(id);
        setquery(newarr);
        toast.success("query Deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setquery(QueryData);
  }, [QueryData]);
  return (
    <>
      {QueryData.length ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <Toaster />
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>
            {query.map((item, index) => (
              <tbody>
                <NtableRow
                  key={item._id}
                  id={index + 1}
                  uuid={item._id}
                  name={item.englishInfo}
                  link={item.link}
                  active={item.active}
                  photo={item.photo}
                  handleDelete={() => handleDelete(item._id)}
                />
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};

export default Ntable;
