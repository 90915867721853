/** @format */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingPage from '../utils/LoadingPage';
import addIcon from '../../images/addIcon.svg';
import searchIcon from '../../images/searchIcon.svg';
import '../../styles/newstyles/property.css';
import AFtable from './AllFeatured/AFtable';
import { getAllProperty, GetAllVideos, GetFeatureVideo } from '../../redux/api';
import { Pagination } from 'react-bootstrap';
import DynamicPagination from '../Pagination/Pagination';

const FeaturedVideos = () => {
	const history = useHistory();
	const [allpropertyData, setallpropertyData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchInput, setsearchInput] = useState('');
	const [videoCount, setVideoCount] = useState(0);
	const [page, setPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
	const [filterData, setfilterData] = useState([]);
	const pageLimit = Math.ceil(videoCount / 10);

	const fetchpropertyList = async () => {
		setLoading(true);
		try {
			const res = await GetFeatureVideo(page);
			console.log(res);
			setallpropertyData(res?.data?.data);
			setVideoCount(res?.data?.pageLimit);
			setTotalPages(res?.data?.pageLimit);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchpropertyList();
	}, [page]);

	const searchItems = (searchValue) => {
		setsearchInput(searchValue);
		if (searchValue !== '') {
			let filteredData = allpropertyData.filter((item) => {
				return Object.values(item)
					.join('')
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			});
			setfilterData(filteredData);
		} else {
			setfilterData(allpropertyData);
		}
	};

	return (
		<div className='property-container'>
			{loading ? (
				<LoadingPage />
			) : (
				<>
					<div className='property-firstSection'>
						<div className='property-searchDiv'>
							<img src={searchIcon} alt='search' className='searchIcon' />
							<input
								type='text'
								placeholder='Enter a Title , Author or Category'
								className='property-searchInput'
								id='searchInput'
								value={searchInput}
								onChange={(e) => searchItems(e.target.value)}
							/>
						</div>
						<div className='property-addpropertyDiv'>
							<button
								className='property-addBtn'
								onClick={() => history.push('/featuredvideos/add')}
							>
								<img src={addIcon} alt='add' className='property-addIcon' />
								<span>Add Video</span>
							</button>
						</div>
					</div>
					<div className='property-tableSection'>
						{searchInput.length ? (
							<AFtable VideoData={filterData} />
						) : (
							<AFtable VideoData={allpropertyData} />
						)}
					</div>
					<div className='property-pagination d-flex justify-content-end mb-5'>
						<div>
							{/* <Pagination>
                <Pagination.First onClick={() => setPage(0)} />
                <Pagination.Prev
                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
                />

                {[...Array(pageLimit)].map((pa, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(index)}
                    active={index === page}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setPage((prev) => prev + 1)} />
                <Pagination.Last onClick={() => setPage(pageLimit)} />
              </Pagination> */}
							<DynamicPagination
								totalPages={totalPages}
								setPage={setPage}
								page={page}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default FeaturedVideos;
