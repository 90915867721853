import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUnitDetail, replyToMail } from "../../../redux/api";
import DeleteModal from "../../utils/DeleteModal";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

const QtableRow = ({ name, index, phone, query, id, handleDelete, email }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    mail: email,
    message: "",
    subject: "",
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const replyApi = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await replyToMail(data);
      toast.success("Mail Sent");
      setLoading(false);
      setShow(false);
    } catch {
      setLoading(false);
      toast.error("Mail Not Sent");
    }
  };

  return (
    <>
      <Modal size="md" show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reply Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => replyApi(e)}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                type="text"
                value={name}
                readOnly
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <label htmlFor="exampleInputEmail1">Phone</label>
              <input
                type="text"
                value={phone}
                readOnly
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input
                type="email"
                value={email}
                readOnly
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <label htmlFor="exampleInputEmail1">Enter Subject</label>
              <input
                type="text"
                name="subject"
                onChange={(e) => handleChange(e)}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <label htmlFor="exampleInputEmail1">Enter Message</label>
              <textarea
                name="message"
                onChange={(e) => handleChange(e)}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <button
              type="submit"
              className="property-addBtn mt-2"
              disabled={loading}
              style={{ borderRadius: "8px" }}
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </form>
        </Modal.Body>
      </Modal>
      <tr>
        <td>{index + 1}</td>
        <td>{name}</td>
        <td>{phone}</td>
        <td>{email}</td>
        <td>{query}</td>
        <td>
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => handleDelete(id)}
              className="btn btn-outline-danger"
            >
              {" "}
              <DeleteIcon />
            </button>
            <button
              onClick={() => setShow(true)}
              className="btn-outline-success-2 mx-2"
            >
              Reply
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default QtableRow;
