/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingPage from '../utils/LoadingPage';
import addIcon from '../../images/addIcon.svg';
import searchIcon from '../../images/searchIcon.svg';
import '../../styles/newstyles/unitdetails.css';
import Utable from './AllUser/Utable';
import { GetQuery, GetUsers } from '../../redux/api';
import exportFromJSON from 'export-from-json';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DynamicPagination from '../Pagination/Pagination';

const User = () => {
	const history = useHistory();
	const { id } = useParams();
	const [UserData, setUserData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchInput, setsearchInput] = useState('');
	const [page, setPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
	const [filterData, setfilterData] = useState([]);

	useEffect(() => {
		getAllUser();
	}, [page, searchInput]);

	const getAllUser = async () => {
		try {
			const data = await GetUsers(page, searchInput);
			setUserData(data?.data?.data);
			setTotalPages(Math.ceil(data?.data?.pageLimit / 10));
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const searchItems = (searchValue) => {
		setsearchInput(searchValue);
		if (searchValue !== '') {
			let filteredData = UserData.filter((item) => {
				return Object.values(item)
					.join('')
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			});
			setfilterData(filteredData);
		} else {
			setfilterData(UserData);
		}
	};

	const downloadUsers = () => {
		const fileName = 'users';
		const exportType = exportFromJSON.types.csv;
		const userWithouPassword = UserData.map((user) => {
			return {
				_id: user._id,
				name: user.name,
				email: user.email,
				phone: user.phone,
				username: user.username,
				organisation: user.organisation,
				designation: user.designation,
				age: user.age,
				address: user.address,
				profile: user.profile,
				status: user.status,
				createdAt: user.createdAt,
				updatedAt: user.updatedAt,
			};
		});
		const data = userWithouPassword;
		exportFromJSON({ data, fileName, exportType });
	};

	return (
		<div className='unitdetails-container'>
			{loading ? (
				<LoadingPage />
			) : (
				<>
					<div className='unitdetails-firstSection'>
						<div className='unitdetails-searchDiv'>
							<img src={searchIcon} alt='search' className='searchIcon' />
							<input
								type='text'
								placeholder='Search Name, Username, Email, Phone...'
								className='unitdetails-searchInput'
								id='searchInput'
								value={searchInput}
								onChange={(e) => setsearchInput(e.target.value)}
							/>
						</div>
						<button
							className='btn-primary-admin'
							onClick={() => downloadUsers()}
						>
							<CloudDownloadIcon /> Export Users
						</button>
					</div>
					<div className='unitdetails-tableSection'>
						{/* {searchInput.length ? (
							<Utable UserData={filterData} />
						) : ( */}
						<Utable UserData={UserData} />
						{/* )} */}
					</div>
					<div className='d-flex justify-content-end'>
						<DynamicPagination
							totalPages={totalPages}
							setPage={setPage}
							page={page}
						/>
					</div>
				</>
			)}
		</div>
	);
};
export default User;
