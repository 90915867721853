/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingPage from '../utils/LoadingPage';
import addIcon from '../../images/addIcon.svg';
import searchIcon from '../../images/searchIcon.svg';
import '../../styles/newstyles/unitdetails.css';
import Ptable from './AllPlaylist/Ptable';
import {
	GetCategory,
	GetPlaylist,
	GetQuery,
	PostCategory,
} from '../../redux/api';

const Playlist = () => {
	const history = useHistory();
	const { id } = useParams();
	const [QueryData, setQueryData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchInput, setsearchInput] = useState('');
	const [filterData, setfilterData] = useState([]);
	useEffect(() => {
		GetData();
	}, []);

	// api call

	const GetData = async () => {
		try {
			const data = await GetPlaylist();
			setQueryData(data?.data?.playlist);
			console.log(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const searchItems = (searchValue) => {
		setsearchInput(searchValue);
		if (searchValue !== '') {
			let filteredData = QueryData.filter((item) => {
				return Object.values(item)
					.join('')
					.toLowerCase()
					.includes(searchValue.toLowerCase());
			});
			setfilterData(filteredData);
		} else {
			setfilterData(QueryData);
		}
	};

	return (
		<div className='unitdetails-container'>
			{loading ? (
				<LoadingPage />
			) : (
				<>
					<div className='unitdetails-firstSection'>
						<div className='unitdetails-searchDiv'>
							<img src={searchIcon} alt='search' className='searchIcon' />
							<input
								type='text'
								placeholder='Enter a Name , Description or More'
								className='unitdetails-searchInput'
								id='searchInput'
								value={searchInput}
								onChange={(e) => searchItems(e.target.value)}
							/>
						</div>
						<div className='property-addpropertyDiv'>
							<button
								className='property-addBtn'
								onClick={() => history.push('/playlist/add')}
							>
								<img src={addIcon} alt='add' className='property-addIcon' />
								<span>Add Playlist</span>
							</button>
						</div>
					</div>
					<div className='unitdetails-tableSection'>
						{searchInput.length ? (
							<Ptable QueryData={filterData} />
						) : (
							<Ptable QueryData={QueryData} />
						)}
					</div>
				</>
			)}
		</div>
	);
};
export default Playlist;
