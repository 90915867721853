import React, { useState, useEffect } from "react";
import AFVtableRow from "./AFVtableRow";
import "../../../styles/newstyles/table.css";

const AFVtable = ({ VideoData, handleAddition, handleRemoval }) => {
  console.log(VideoData);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Likes</th>
            <th>Dislikes</th>
            <th>Category</th>
            <th>Playlist</th>
            <th>Uploaded by</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {VideoData &&
            VideoData.map((video, index) => {
              return (
                <AFVtableRow
                  key={index}
                  videoid={video._id}
                  index={index}
                  title={video.title}
                  description={video.description}
                  uploadBy={video.uploadedBy}
                  handleRemoval={handleRemoval}
                  handleAddition={handleAddition}
                  date={video.date}
                  category={
                    video.category
                      ? video.category.name
                      : "No Category Assigned"
                  }
                  playlist={
                    video.playlist
                      ? video.playlist.name
                      : "No Playlist Assigned"
                  }
                  size={VideoData?.length}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AFVtable;
