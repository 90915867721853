import React from 'react';
import ADTableRow from './ADTableRow';
import '../../../styles/newstyles/table.css';

const ADTable = ({ UserData }) => {
  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {UserData &&
            UserData.map((item, index) => {
              return <ADTableRow key={index} username={item.username} index={index} designation={item.designation} email={item.email} name={item.name} phone={item.phone} age={item.age} organisation = {item.organisation}/>;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ADTable;
