import React, { useState, useEffect } from "react";
// import { DeleteQuery } from "../../../redux/api";
// import "../../../styles/newstyles/table.css";
import NtableRow from "./NtableRow";
import toast, { Toaster } from "react-hot-toast";

const Ntable = ({ QueryData }) => {
  const [query, setquery] = useState([]);

  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        const newarr = query.filter((item) => item._id != id);
        // await DeleteQuery(id);
        setquery(newarr);
        toast.success("query Deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setquery(QueryData);
  }, [QueryData]);
  return (
    <>
      {QueryData ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <Toaster />
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <NtableRow
                key={query._id}
                id={query._id}
                name={query.englishInfo}
                link={query.link}
              />
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};

export default Ntable;
