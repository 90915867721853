/** @format */

export const ShowActive = ({
	id,
	title,
	icon,
	active,
	setSelected,
	image,
	className,
}) => {
	return (
		<li
			key={id}
			style={{ width: '200px' }}
			className={`${className} ${active ? 'active' : ''}`.trim()}
			onClick={() => setSelected(id)}
		>
			<div className='d-flex justify-content-between'>
				<div>
					<p>{title}</p>
				</div>
			</div>
		</li>
	);
};
