import React from "react";

export const SearchTableRow = ({ name, index, phone, id, handleDelete }) => {
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td className="text-left">{name}</td>
      </tr>
    </>
  );
};
