import React, { useEffect, useState } from "react";
import {
  GetCategory,
  GetPlaylist,
  playListById,
  PostCategory,
  PostPlaylist,
} from "../../redux/api";
import translate from "translate";
import { useHistory, useParams } from "react-router-dom";

function AddPlaylist() {
  const [spinn, setspinn] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [playlist, setPlaylist] = useState([]);
  const [filterdPlaylist, setFilterdPlaylist] = useState({});
  const [hindi, setHindi] = useState("");
  const [marathi, setMarathi] = useState("");
  const [telugu, setTelugu] = useState("");
  const [tamil, setTamil] = useState("");
  const [kannad, setKannad] = useState("");
  const [playlistData, setplaylistData] = useState({
    name: "",
    hindiInfo: "",
    tamilInfo: "",
    teluguInfo: "",
    marathiInfo: "",
    kannadInfo: "",
  });
  const translateIntoLng = async (key, value) => {
    for (let i = 1; i < 6; i++) {
      if (i === 1) {
        const data = await translate(value, { to: "hi" });
        setHindi(data);
      }
      if (i === 2) {
        const data = await translate(value, { to: "te" });
        setTelugu(data);
      }
      if (i === 3) {
        const data = await translate(value, { to: "mr" });
        setMarathi(data);
      }
      if (i === 4) {
        const data = await translate(value, { to: "ta" });
        setTamil(data);
      }
      if (i === 5) {
        const data = await translate(value, { to: "kn" });
        setKannad(data);
      }
    }
  };

  useEffect(() => {
    if (hindi && telugu && marathi && tamil) {
      setplaylistData({
        ...playlistData,
        hindiInfo: hindi,
        tamilInfo: tamil,
        teluguInfo: telugu,
        marathiInfo: marathi,
        kannadInfo: kannad,
      });
      setFilterdPlaylist({
        ...filterdPlaylist,
        hindiInfo: hindi,
        tamilInfo: tamil,
        teluguInfo: telugu,
        marathiInfo: marathi,
        kannadInfo: kannad,
        name: playlistData.name,
      });
    }
  }, [hindi, marathi, tamil, telugu, kannad]);
  const handlesubmit = async (e) => {
    e.preventDefault();
    setspinn(true);
    if (params.id) {
      const data = {
        name: playlistData.name || filterdPlaylist.name,
        hindiInfo: playlistData.hindiInfo || filterdPlaylist.hindiInfo,
        tamilInfo: playlistData.tamilInfo || filterdPlaylist.tamilInfo,
        teluguInfo: playlistData.teluguInfo || filterdPlaylist.teluguInfo,
        marathiInfo: playlistData.marathiInfo || filterdPlaylist.marathiInfo,
        kannadInfo: playlistData.kannadInfo || filterdPlaylist.kannadInfo,
      };
      try {
        const res = await playListById(params.id, data);
        setspinn(false);
        history.push("/playlist");
      } catch (err) {
        console.log(err);
        setspinn(false);
        alert(err.message);
      }
    } else {
      try {
        const data = await PostPlaylist(playlistData);
        console.log(data);
        setspinn(false);
        history.push("/playlist");
      } catch (error) {
        console.log(error);
        setspinn(false);
        alert("Please Fill All Required Information");
      }
    }
  };
  useEffect(() => {
    const playlistFetch = async () => {
      try {
        const data = await GetPlaylist();
        setPlaylist(data?.data?.playlist);
      } catch (error) {
        console.log(error);
      }
    };
    playlistFetch();
  }, []);

  useEffect(() => {
    if (playlist.length) {
      const filteredPlaylist = playlist.find((item) => item._id === params.id);
      setFilterdPlaylist(filteredPlaylist);
    }
  }, [params.id, playlist]);

  // const handlesubmit=async(e)=>{
  //   e.preventDefault();
  //   try {
  //     let temp   ={
  //                category :["hello","new"]
  //         }
  //     const data = await PostCategory(temp);
  //         console.log(data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      translateIntoLng("englishInfo", value);
      setplaylistData({ ...filterdPlaylist, name: value });
    }
    if (name === "hindiInfo") {
      translateIntoLng("hindiInfo");
    }
    if (name === "tamilInfo") {
      translateIntoLng("tamilInfo");
    }
  };

  return (
    <>
      <form>
        <div className="addproperty-container mb-5">
          <div className="addproperty-personalDetails">
            {/* 1st row */}
            <div
              className="addproperty-alignRow "
              style={{ flexDirection: "column" }}
            >
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Playlist (English)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  defaultValue={filterdPlaylist?.name || ""}
                  type="text"
                  name="name"
                  placeholder="Enter Playlist name in English"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2 ">
                <label className="addproperty-inputLabel ">
                  Playlist (Hindi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  defaultValue={filterdPlaylist?.hindiInfo || ""}
                  type="text"
                  name="hindiInfo"
                  placeholder="Enter Playlist name in Hindi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Playlist (Tamil)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  defaultValue={filterdPlaylist?.tamilInfo || ""}
                  type="text"
                  name="tamilInfo"
                  placeholder="Enter Playlist name in Tamil"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Playlist (Telugu)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  defaultValue={filterdPlaylist?.teluguInfo || ""}
                  type="text"
                  name="teluguInfo"
                  placeholder="Enter Playlist name in Telugu"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Playlist (Marathi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  defaultValue={filterdPlaylist?.marathiInfo || ""}
                  type="text"
                  name="marathiInfo"
                  placeholder="Enter Playlist name in Marathi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Playlist (Kannad)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  defaultValue={filterdPlaylist?.kannadInfo || ""}
                  type="text"
                  name="kanadInfo"
                  placeholder="Enter Playlist name in Kannad"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              {params.id ? "Update" : "Submit"}
              {spinn ? (
                <div
                  class="spinner-border spinner-border-sm text-white mx-2"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddPlaylist;
