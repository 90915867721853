import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoadingPage from "../../utils/LoadingPage";
import addIcon from "../../../images/addIcon.svg";
import searchIcon from "../../../images/searchIcon.svg";
import "../../../styles/newstyles/unitdetails.css";
import PVtable from "./AllPlaylistVideos/PVtable";
import {
  GetAllVideos,
  GetAllVideosOfPlaylist,
  GetCategory,
  GetQuery,
  PostCategory,
} from "../../../redux/api";
import { Pagination } from "react-bootstrap";

const PlaylistVideos = () => {
  const history = useHistory();
  const { name, id } = useParams();
  const param = useParams();
  const [QueryData, setQueryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [page, setPage] = useState(0);
  const pageLimit = Math.ceil(videoCount / 10);
  useEffect(() => {
    GetData();
  }, [page]);

  // api call

  const GetData = async () => {
    try {
      const data = await GetAllVideosOfPlaylist(id, page, 1000);
      setQueryData(data?.data?.data);
      setVideoCount(data?.data?.pageLimit);
      console.log(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchItems = (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      let filteredData = QueryData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setfilterData(filteredData);
    } else {
      setfilterData(QueryData);
    }
  };

  return (
    <div className="unitdetails-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="unitdetails-firstSection">
            <div className="unitdetails-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Name , Description or More"
                className="unitdetails-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() =>
                  history.push(`/playlist/addvideos/${name}/${id}`)
                }
              >
                <img src={addIcon} alt="add" className="property-addIcon" />
                <span>Add Videos</span>
              </button>
            </div>
          </div>
          <h5 className="my-4">{name}</h5>
          <div className="unitdetails-tableSection">
            {searchInput.length ? (
              <PVtable QueryData={filterData} />
            ) : (
              <PVtable QueryData={QueryData} />
            )}
          </div>
          {/* <div className="property-pagination d-flex justify-content-end mb-5">
            <div>
              <Pagination>
                <Pagination.First onClick={() => setPage(0)} />
                <Pagination.Prev
                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
                />

                {[...Array(pageLimit)].map((pa, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(index)}
                    active={index === page}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setPage((prev) => prev + 1)} />
                <Pagination.Last onClick={() => setPage(pageLimit)} />
              </Pagination>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
export default PlaylistVideos;
