/** @format */

import { useState } from 'react';
import { useEffect } from 'react';
import { GetAllVideos } from '../../redux/api';

export const GetPlaylistViewLikes = (limit) => {
	const [video, setVideo] = useState([]);
	const [page, setPage] = useState(0);
	const [data, setData] = useState([]);
	let i = 0;
	useEffect(() => {
		if (limit) {
			const getVideo = async () => {
				for (i = 0; i < limit; i++) {
					const data = await GetAllVideos(i);
					setData(data?.data?.data);
					setPage(page + 1);
				}
			};
			getVideo();
		}
	}, [limit, i]);

	useEffect(() => {
		setVideo([...video, ...data]);
	}, [data]);

	if (!video?.length) return [];
	const newPlaylist =
		video
			.map((item) => {
				return {
					playlist: item.playlist,
					stats: item.videoStatId,
					item: item,
				};
			})
			?.reduce((acc, current) => {
				const id = current.playlist && current.playlist._id;
				const playId = acc[id];
				if (playId) {
					Object.assign(acc[id], {
						totalViews: playId.totalViews + current?.stats?.views,
						totalLikes: playId.totalLikes + current?.stats?.likes,
						totalVideoes: playId.totalVideoes + 1,
						totalDuration: playId.totalDuration + current.item.duration,
					});
				} else {
					Object.assign(acc, {
						[id]: {
							totalViews: current?.stats?.views,
							totalLikes: current?.stats?.likes,
							totalDuration: current?.item?.duration,
							playListName: current?.playlist && current?.playlist?.name,
							totalVideoes:
								current?.item?.englishInfo?.url?.length > 10 ? 1 : 0,
						},
					});
				}
				return acc;
			}, {}) || {};

	const playlist = Object.values(newPlaylist).filter(
		(item) => item.playListName
	);
	console.log('PlayName', playlist);

	let totalViews = 0;
	for (const rev of playlist) {
		if (Number(rev.totalViews)) {
			totalViews += rev.totalViews;
		}
	}
	// const totalViews = playlist.reduce(
	// 	(acc, curr) => acc + curr?.totalViews || 0,
	// 	0
	// );

	return {
		playlist,
		totalViews,
	};
};

export const videoKey = (key) => {
	const data = {
		hindiInfo: 'hi',
		englishInfo: 'en',
		tamilInfo: 'ta',
		teluguInfo: 'te',
		marathiInfo: 'mr',
	};
	return data[key];
};
