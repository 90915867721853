import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteProperty } from '../../../redux/api';
import DeleteModal from '../../utils/DeleteModal';
const AFVtableRow = ({index,title,uploadBy ,videoid, description ,date ,category ,size,playlist,handleAddition,handleRemoval}) => {
  // const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [active, setactive] = useState(true)
  

  return (
    <>
      <tr>
        <td>{size - (index)}</td>
      
        <td>{title}</td>
        <td>{1}</td>
        <td>{1}</td>
        <td>{category}</td>
        <td>{playlist}</td>
        <td>{uploadBy}</td>
        {
        active ? (
            <td><button onClick={()=>{
                handleAddition(videoid)
                    setactive(false)                
                }} className={`btn btn-outline-primary  `}>+</button></td>
        ):(
            <td><button onClick={()=>{
                 handleRemoval(videoid)
                setactive(true)
                }} className={`btn btn-outline-danger  `}>x</button></td>

        )
    }
    </tr>

    </>
  );
};

export default AFVtableRow;
