import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUnitDetail } from "../../../redux/api";
import { SortableHandle } from "react-sortable-hoc";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const CtableRow = ({ name, index, phone, query, id, handleDelete }) => {
  const RowHandler = SortableHandle(() => <DragIndicatorIcon />);

  return (
    <>
      <tr>
        {/* <td>
          
          {id.slice(3, 7)}
        </td> */}
        <td className="handle" style={{ cursor: "grab" }}><RowHandler /> {name}</td>
        <td className="text-right">
          <div
            className="actions"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <button onClick={() => handleDelete(id)} className="delete-btn">
              <DeleteIcon />{" "}
            </button>
            <Link to={`/category/edit/${id}`}>
              <button className="edit-btn">
                <ModeEditIcon />
              </button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

export default CtableRow;
