import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";

export const FaqTable = ({
  name,
  phone,
  query,
  index,
  id,
  handleDelete,
  link,
}) => {
  const history = useHistory();
  console.log(name);
  return (
    <>
      <tr>
        <td>{index}</td>
        <td style={{ width: "30%" }}>{name}</td>
        <td>{link}</td>
        <td>
          <button
            onClick={() => history.push("/faq/add/" + id)}
            className="btn btn-outline-success mx-2"
          >
            <EditIcon />
          </button>
          <button
            onClick={() => handleDelete(id)}
            className="btn btn-outline-danger mx-2"
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>
    </>
  );
};
